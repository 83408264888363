import { Component } from '@angular/core';
import { MeetingService } from '../meeting.service';
import { Roles } from '../models/Roles';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
  host: { class: 'd-flex h-100 overflow-hidden' },
})
// This is the main game component
// This is where users arive after creating a room
export class GameComponent {
  constructor(protected core: MeetingService) {}

  protected userIsFacilitator(): boolean {
    // Returns true if the user is a facilitator
    console.log(
      'userIsFacilitator',
      sessionStorage.getItem('role') === Roles.FACILITATOR
    );
    return sessionStorage.getItem('role') === Roles.FACILITATOR;
  }
}
