
// A convenience function which concatenates its arguments with '/' in between.
export function path(...args: string[]) {
  return args.join('/');
}

// A convenience functions which creates a pair of elements
export function pair<A,B>(a:A,b:B): [A,B] {
  return [a,b]
}


// Takes an array and a function on its elements and returns an object of groups of elements.
// This functions groups elements together when they have the same `by` result.
export function arrayGroupBy<A>(list: A[], by: (a: A) => string): {[key: string]: A[]} {
  let res: {[key: string]: A[]} = {}

  for (let a of list) {
    const key = by(a)

    if (!(key in res)) {
      res[key] = []
    }

    res[key].push(a)
  }

  return res
}

// Swaps two elements in an array
function swap<A>(list: A[], i: number, j: number) {
  const x = list[i]
  list[i] = list[j]
  list[j] = x
}

// Returns a random integer in some range
export function randInt(lb: number, ub: number): number {
  return Math.floor(Math.random() * (1 + ub - lb)) + lb
}

// Selects random elements from a given array.
// The result (as a mutliset) is always a valid sub-multiset of the input.
export function selectRandomN<A>(list: A[], amount: number): A[] {
  let l = list.slice()

  let n = 0

  while (n < Math.min(list.length, amount)) {
    const index = randInt(n, list.length - 1)

    swap(l, n, index)

    n++
  }

  return l.slice(0, n)
}
