<h2 class="text-center">Participants</h2>
<ul class="list-group list-group-flush">
  <li
    *ngFor="let user of joinedUsers"
    class="list-group-item d-inline-flex justify-content-center align-items-center"
  >
    <i
      class="fa-solid fa-circle pe-2"
      [ngClass]="user.online ? 'text-success' : 'text-danger'"
    ></i>
    <span class="flex-fill">{{ user.name }}</span>
    <i
      *ngIf="canKickUser(user.uid)"
      class="fa-regular fa-circle-xmark fa-xl"
      (click)="kickUser(user.uid)"
    ></i>
  </li>
</ul>
