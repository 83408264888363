<div class="container-fluid h-100 d-flex flex-column">
  <div class="d-flex">
    <h2 class="flex-grow-1 text-capitalize">{{ currentBlockVariant }}</h2>
    <app-leave-button />
  </div>

  <div
    *ngFor="let cardGroup of getBlockDisplayCards()"
    style=""
  >
    <app-card
      *ngFor="let card of _Object.values(cardGroup.cards)"
      [cardData]="card"
    ></app-card>
  </div>

  <hr class="hr" />
  <div *ngIf="shouldShowTimerDialogue()">
    <!-- Time is up!
  <br>
  <button (click)="timerDialogeFinishNextActivity()">{{core.getNextActivityButtonText()}}</button>
  <br>
  <button (click)="killTimer()">Ignore</button> -->
  </div>
  <div *ngIf="shouldShowTimer()">
    <span class="badge bg-primary">{{ getTimerValue() }}</span>
  </div>
  <app-answers-block
    *ngIf="currentBlockVariant == blockVariants.INDIVIDUAL_ANSWERS"
    class="flex-grow-1"
  ></app-answers-block>
  <app-answers-block
    *ngIf="currentBlockVariant == blockVariants.DISCUSSION"
  ></app-answers-block>
  <app-voting-block
    *ngIf="currentBlockVariant == blockVariants.VOTING"
    class="flex-grow-1"
  ></app-voting-block>
  <app-start-block
    *ngIf="currentBlockVariant == blockVariants.START"
    class="d-flex flex-column justify-content-center flex-grow-1"
  ></app-start-block>
  <app-section-definer
    *ngIf="currentBlockVariant == blockVariants.SECTION_DEFINER"
    class="d-flex flex-column justify-content-center flex-grow-1"
  ></app-section-definer>
</div>
