import { Component } from '@angular/core';
import { MeetingService } from '../meeting.service';
import { TimerService } from '../timer.service';
import { Subscription } from 'rxjs';
import { Roles } from '../models/Roles';
import { BlockVariant } from 'src/template/BuildingBlockData';
import { CardGroupWithData } from '../models/CardGroupWithData';
import { BlockService } from '../block.service';
import { FlowEventService } from '../flow-event.service';

@Component({
  selector: 'app-building-block',
  templateUrl: './building-block.component.html',
  styleUrls: ['./building-block.component.scss'],
})
export class BuildingBlockComponent {

  protected _Object = Object

  private blockSub?: Subscription

  protected blockVariants = BlockVariant

  constructor(
    protected core: MeetingService,
  ) { }

  private get blockService() { return this.core.packageService.blockService }
  private get flowEventService() { return this.core.packageService.flowEventService }
  protected get timerService() { return this.core.timerService }


  ngOnInit() {
    this.timerService.init()
  }

  ngOnDestroy() {
    this.blockSub?.unsubscribe()

    this.timerService.destroy()
  }

  protected getBlockDisplayCards(): CardGroupWithData[] {
    // Returns the cards to display at the top of the block

    // This is not allowed for voting blocks
    if (this.blockService.currentBlock?.variant === BlockVariant.VOTING) return []

    const inputResults = this.flowEventService.currentInputSettingResults
    // If there are no input results, return an empty array
    if (!inputResults) return []

    // If there are input results, return the cards
    if ("input" in inputResults) {
      return Object.values(inputResults['input'])
    }

    return []
  }

  protected userIsFacilitator(): boolean {
    // Returns true if the user is a facilitator
    console.log(sessionStorage.getItem('role') === Roles.FACILITATOR)
    return sessionStorage.getItem('role') === Roles.FACILITATOR
  }

  protected timerDialogeFinishNextActivity() {
    // This function is called when the timer dialogue is finished
    // It will finish the current activity and move to the next one
    this.core.nextActivity()
  }

  protected showBlock() {
    // This is a debug function to show the JSON of the current block
    return JSON.stringify(this.blockService.currentBlock)
  }

  protected get currentBlockVariant(): BlockVariant | undefined {
    // Returns the current block variant
    return this.blockService.currentBlock?.variant
  }

  protected shouldShowTimer(): boolean {
    // Returns true if the timer should be shown
    return this.timerService.isActive()
  }

  protected getTimerValue() {
    // Returns the timer value in the format mm:ss
    var millis = this.timerService.getTimeLeft() || 0;
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    // If the timer is up, return "Time is up!"
    if (millis <= 0) {
      return "Time is up!"
    // If the timer is not up, return the time in the format mm:ss
    } else {
      return minutes + ":" + (parseFloat(seconds) < 10 ? '0' : '') + seconds;
    }

  }

  protected shouldShowTimerDialogue() {
    // Returns true if the timer dialogue should be shown
    const timeLeft = this.timerService.getTimeLeft()
    // If the time left is undefined, or there is still time left, or the user is not a facilitator, return false
    return timeLeft !== undefined
      && timeLeft <= 0
      && sessionStorage.getItem('role') === Roles.FACILITATOR
  }

  protected killTimer() {
    this.timerService.killTimer()
  }
}
