import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VCAuthService, VCRoomService, VCUserService } from '@gametailors/v-cilitator-angular';
import { Roles } from '../models/Roles';
import { MeetingService } from '../meeting.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-join-room',
  templateUrl: './join-room.component.html',
  styleUrls: ['./join-room.component.scss']
})
export class JoinRoomComponent {

  @ViewChild('username') usernameElm?: ElementRef

  private subscriptions: Subscription[] = []
  protected roomCode?: string
  private googleUserName?: string
  public loginCounter: number = 0

  constructor (
    protected vca: VCAuthService,
    private vcr: VCRoomService,
    private core: MeetingService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private vcu: VCUserService
  ) {}

  ngOnInit() {
    // Get the room code from the query parameters
    const sub1 = this.activatedRouter.queryParams.subscribe(params => {
      this.roomCode = params['room']
    })
    
    this.subscriptions.push(sub1)

    // Subscribe to the onLoggedIn event
    const sub2 = this.vca.onloggedIn.subscribe(x => {
      
      this.loginCounter += 1
      const loginCount = this.loginCounter
      // If the user is logged in, get their name
      if (this.vca.loggedInWithGoogle) {
        this.vcu.getUserInfo(this.vca.currentUserUid, ({userInfo}) => {
          if (this.loginCounter === loginCount) this.googleUserName = userInfo.name
        }, err => {
          console.log('Failed to retrieve user info', err)
          if (this.loginCounter === loginCount)  this.logout()
        })
      } else {
        this.googleUserName = undefined
      }
    })

    this.subscriptions.push(sub2)
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe()
    }
  }

  protected get displayUserName(): string {
    // If the user is logged in with Google, display their name
    // Otherwise, display the "..."
    return this.username || "..."
  }

  protected get readyToJoinWithGoogle(): boolean {
    // If the user is logged in with Google, and their name is defined, return true
    return this.vca.loggedInWithGoogle && this.googleUserName !== undefined
  }

  private get username(): string | undefined {
    // If the user is logged in with Google, return their name
    return this.googleUserName || this.usernameElm?.nativeElement.value
  }

  protected login() {
    // Logs the user in with Google's login popup
    this.vca.googleLogin()
  }

  protected logout() {
    // Logs the user out
    this.vca.logout()
  }

  protected cancel() {
    // Cancels the room creation process
    this.router.navigate([""])
  }

  protected joinRoomWithCode() {
    // Joins the room with the room code

    if (!this.username) return
    if (this.username === "") return

    // If the room code is not defined, navigate to the home page and return
    if (!this.roomCode) {
      this.router.navigate([""])
      return
    }

    let success = true

    // Else join the user as an attendee to the room
    this.core.joinRoom(
      this.roomCode, 
      Roles.ATTENDEE, 
      this.username || "",
      false, 
      () => {},
      () => {
        this.router.navigate([""])
        success = false
      }
    )
    // If the user was successfully joined to the room, start pinging the room
    if (success) this.vcr.startRoomPinging()
  }
}
