import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { VCAuthService, VCRoomService } from '@gametailors/v-cilitator-angular';

@Component({
  selector: 'app-leave-button',
  templateUrl: './leave-button.component.html',
  styleUrls: ['./leave-button.component.scss'],
})
export class LeaveButtonComponent {
  constructor(
    private vcr: VCRoomService,
    private vca: VCAuthService,
    private router: Router,
  ) {}

  protected leave() {
    // Leaves the room and navigates to the home page
    this.tryLeaveRoom()
    // If the user left the room then stop pinging the room
    this.vcr.stopRoomPinging();
    // And remove the user's data from the session storage
    sessionStorage.removeItem('gameCode');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('role');
    // At last, navigate to the home page
    this.router.navigate(['']);
  }

  private tryLeaveRoom() {
    // FIXME: This try is here because if the game is running and if the user reloads the page then they don't have access to the user list
    // and so they are not kicked automatically and are still present in the room, and for some reason this causes an exception to be thrown here.
    try {
      this.vcr.exitRoom(this.vca.currentUserUid);
    } catch (e) {
      console.error(e)
    }
  }
}
