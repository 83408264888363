import { FlowConnectionData } from "./FlowConnectionData"
import { ValueSettingData } from "./SettingData"
import { InputSettingData } from "./SettingData"
import { InputData, SettingData } from "./SettingData"
import { Author, GroupCards, MaxNumberOfVotesPerAnswerpp, MaxNumberOfVotesPerAuthorpp, NumberOfAnswerspp, WhoCanAnswer } from "./SettingValues"


export enum BlockVariant {
  START = 'start',
  SECTION_DEFINER = 'sectionDefiner',
  INDIVIDUAL_ANSWERS = 'individualAnswers',
  VOTING = 'voting',
  DISCUSSION = 'discussion',
  PAUSE = 'pause'
}


export type MkBlock<Variant extends BlockVariant, Settings extends {[settingId:string]:SettingData<any>}> = {
    variant: Variant,
    name: string,
    flowConnectionsOut?: string[]
    settings: Settings
}


export type BuildingBlockData 
  = StartBlockData
  | SectionDefinerBlockData
  | IndividualAnswersBlockData
  | VotingBlockData
  | DiscussionBlockData
  | PauseBlockData

export type PauseBlockData = MkBlock<BlockVariant.PAUSE, PauseBlockSettings>  
type PauseBlockSettings = {}

export type StartBlockData = MkBlock<BlockVariant.START, StartBlockSettings>
type StartBlockSettings = {
  name: ValueSettingData<string>
  category: ValueSettingData<string>
  description: ValueSettingData<string>
  meetingPurpose: ValueSettingData<string>
  expectation: ValueSettingData<string>
  participants: ValueSettingData<number>
}

export type SectionDefinerBlockData = MkBlock<BlockVariant.SECTION_DEFINER, SectionDefinerSettings>
type SectionDefinerSettings = {
  name: ValueSettingData<string>
  input: InputSettingData<null>
}


export type IndividualAnswersBlockData = MkBlock<BlockVariant.INDIVIDUAL_ANSWERS, IndividualAnswersSettings>
type IndividualAnswersSettings = {
  title: ValueSettingData<string>
  input: InputSettingData<null>
  author: ValueSettingData<Author>
  cardToDisplay: InputSettingData<null>
  numberOfAnswerspp: ValueSettingData<NumberOfAnswerspp>
  numberOfAnswersppAmount: ValueSettingData<number>
  whoCanAnswer: ValueSettingData<WhoCanAnswer>
  markDuplicates: ValueSettingData<boolean>
  displayAnswers: ValueSettingData<string>
  instructions: ValueSettingData<string>
}

export type DiscussionBlockData = MkBlock<BlockVariant.DISCUSSION, DiscussionBlockSettings>
type DiscussionBlockSettings = {
  title: ValueSettingData<string>,
  input: InputSettingData<null>,
  author: ValueSettingData<Author>,
  cardToDisplay: InputSettingData<null>,
  whoCanAnswer: ValueSettingData<WhoCanAnswer>,
  markDuplicates: ValueSettingData<boolean>,
  instructions: ValueSettingData<string>,
}

export type VotingBlockData = MkBlock<BlockVariant.VOTING, VotingBlockSettings>
type VotingBlockSettings = {
  title: ValueSettingData<string>,
  input: InputSettingData<null>,
  author: ValueSettingData<Author>,
  voteLimit: ValueSettingData<number>,
  canVoteOnOwnCards: ValueSettingData<boolean>,
  maxNrOfVotesPerAuthorppOption: ValueSettingData<MaxNumberOfVotesPerAuthorpp>,
  maxNrOfVotesPerAuthorpp: ValueSettingData<number>,
  maxNrOfVotesPerCardppOption: ValueSettingData<MaxNumberOfVotesPerAnswerpp>,
  maxNrOfVotesPerCardpp: ValueSettingData<number>,
  groupCards: ValueSettingData<GroupCards>,
  jointVoting: ValueSettingData<boolean>,
  markDuplicates: ValueSettingData<boolean>,
  instructions: ValueSettingData<string>,
}


