<div class="d-flex flex-column h-100">
  <nav class="navbar navbar-light bg-light">
    <div class="container-fluid">
      <span class="navbar-brand mb-0 h1">Go Fino</span>
      <a class="btn btn-outline-primary" href="{{environment.managerDomain}}" role="button">Manager</a>
    </div>
  </nav>

  <router-outlet></router-outlet>
</div>
