<h3>{{ title }}</h3>
<p>{{ instructions }}</p>

<!-- <div *ngFor="let card of currentAnswers()">
  {{card.text}} - {{getUserName(card.author)}}
  <button *ngIf="shouldShowDeleteAnswerButton()" (click)="deleteAnswer(card)">Delete answer</button>
  <button *ngIf="shouldShowEditAnswerButton()" (click)="editAnswer(card)">Edit answer</button>
</div> -->
<div class="container">
  <div
    class="row"
    *ngFor="let cardGroup of currentAnswers()"
    style=""
  >

  <app-card
  *ngFor="let cardId of _Object.keys(cardGroup.cards)"
  [cardData]="cardGroup.cards[cardId]"
  [isAuthorAnonymous]="
    !isUserAuthorOfCard(cardGroup.cards[cardId]) && isAuthorAnonymous
  "
  ><div
    class="btn-group btn-group-sm"
    role="group"
    aria-label="Answer controls"
  >
    <button
      *ngIf="shouldShowChangeAnswerButtons(cardGroup.cards[cardId])"
      type="button"
      style="background: none; height: auto"
      class="btn-close"
      (click)="editAnswer(cardId, cardGroup.cards[cardId])"
    >
      <i class="fa-solid fa-pen"></i>
    </button>
    <button
      *ngIf="shouldShowChangeAnswerButtons(cardGroup.cards[cardId])"
      type="button"
      style="background: none; height: auto"
      class="btn-close"
      (click)="deleteAnswer(cardId)"
    >
      <i class="fa-solid fa-trash"></i>
    </button>
    <button
      *ngIf="showUnmergeButton(cardGroup)"
      type="button"
      style="background: none; height: auto"
      class="btn-close"
      (click)="unmarkAsDuplicate(cardGroup.groupId, cardId)"
    >
      <i class="fa-solid fa-link-slash"></i>
    </button>
  </div>
</app-card>

    <button
      *ngIf="showStartMergeButton()"
      type="button"
      class="btn btn-link"
      (click)="startMerge(cardGroup)"

    >
      Mark as duplicate
    </button>

    <button
      *ngIf="showCancelMergeButton(cardGroup.groupId)"
      type="button"
      class="btn btn-link"
      (click)="cancelMerge()"
    >
      Cancel
    </button>

    <button
      *ngIf="showCompleteMergeButton(cardGroup.groupId)"
      type="button"
      class="btn btn-link"
      (click)="completeMerge(cardGroup.groupId)"
    >
      Select
    </button>
  </div>
  <div></div>
</div>

<br><br>

<div *ngIf="!showEditAnswerTextArea">
  <div class="input-group mb-3">
    <input
      #answer
      type="text"
      class="form-control"
      [placeholder]="'Add new ' + answerKind"
      aria-label="Add new answer"
      aria-describedby="button-addon2"
    />
    <button
      class="btn btn-outline-secondary"
      (click)="submit(answer.value)"
      type="button"
      id="button-addon2"
    >
      Submit {{ answerKind }}
    </button>
  </div>
</div>

<div *ngIf="showEditAnswerTextArea">
  <div class="input-group mb-3">
    <input
      #edittedAnswer
      type="text"
      class="form-control"
      value="{{ getEditCardText() }}"
      aria-label="Your edited answer"
      aria-describedby="button-addon2"
    />
    <button
      class="btn btn-outline-secondary"
      type="button"
      (click)="submitEdit(edittedAnswer.value)"
      id="button-addon2"
    >
      Submit edit
    </button>
  </div>
</div>
