export enum Timer {
  None = "none",
  Amount_of_time = "amount_of_time",
  Amount_of_time_pp = "amount_of_time_pp",
}

export enum Author {  
  View_author = "view_author",
  Make_anonymous = "make_anonymous",
}

export enum DisplayAnswers {  
  View_as_they_are_submitted = "view_as_they_are_submitted",
  View_once_all_submitted = "view_once_all_submitted"
}

export enum NumberOfAnswerspp {
  Unlimited = "unlimited",
  Amount_pp = "amount_pp",
  Amount_total = "amount_total",
}

export enum WhoCanAnswer {
  Everyone = "everyone",
  Facilitator = "facilitator",
}

export enum MaxNumberOfVotesPerAnswerpp {
  Unlimited = "unlimited",
  Amount_pp = "amount_pp",
}

export enum GroupCards {
  PerAuthor = "per_author",
  No_Grouping = "no_grouping",
}

export enum MaxNumberOfVotesPerAuthorpp {
  Unlimited = "unlimited",
  Amount_pp = "amount_pp",
}

export enum WhoReadsOutLoud {
  Everyone = "everyone_1_by_1",
  Specific_Person = "specific_person",
}

export enum ReadingOrder {
  Choose_at_random = "choose_at_random",
  Predetermined_order = "predetermined_order",
}

export enum FillInTheAxis {
  Fill_in_at_once = "fill_in_at_once",
  Fill_in_one_by_one = "fill_in_1_by_1",
}

