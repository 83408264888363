import { Component } from '@angular/core';
import { MeetingService } from '../meeting.service';
import { FlowEvent } from '../models/FlowEvent';
import { FlowEventService } from '../flow-event.service';
import { download } from '../dowload';

@Component({
  selector: 'app-end-screen',
  templateUrl: './end-screen.component.html',
  styleUrls: ['./end-screen.component.scss']
})
// This is what users see when a meeting is over
export class EndScreenComponent {

  private flowEvents: {[id: string]: FlowEvent} = {}

  constructor(
    private core: MeetingService,
    private flowEventService: FlowEventService
  ) {}

  // FIXME: This seems very wrong and dangerous
  async ngOnInit() {
    this.flowEvents = this.flowEventService.flowEvents
  }

  protected getSummary(): number {
    // Returns the summary
    let count = 0
    // Return the number of values in the output of each flow event
    for (let flow of Object.values(this.flowEvents)) {
      count += Object.values(flow.output || {}).length
    }

    return count
  }

  protected async downloadData() {
    // Downloads the meeting data as a CSV file
    download(
      'meeting_data.csv',
      await this.core.getCsvOutput()
    )
  }
}
