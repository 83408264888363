import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { environment } from '../environments/environment';
import {
  ServiceWorkerModule,
} from '@angular/service-worker';
import { AngularFireModule } from '@angular/fire/compat';
// import {CoreModule} from "./core/core.module";
import { VCilitatorModule } from '@gametailors/v-cilitator-angular';
import { JoinRoomComponent } from './join-room/join-room.component';
import { ParticipantListComponent } from './participant-list/participant-list.component';
import { GameComponent } from './game/game.component';
import { GameButtonsComponent } from './game-buttons/game-buttons.component';
import { BuildingBlockComponent } from './building-block/building-block.component';
import { AnswersBlockComponent } from './answers-block/answers-block.component';
import { EndScreenComponent } from './end-screen/end-screen.component';
import { VotingBlockComponent } from './voting-block/voting-block.component';
import { MeetingStatusComponent } from './meeting-status/meeting-status.component';
import { LeaveButtonComponent } from './leave-button/leave-button.component';
import { providers } from './providers';
import { CreateRoomComponent } from './create-room/create-room.component';
import { UtilitiesComponent } from './utilities/utilities.component';
import { HomeComponent } from './home/home.component';
import { CardComponent } from './card/card.component';
import { StartBlockComponent } from './start-block/start-block.component';
import { SectionDefinerComponent } from './section-definer/section-definer.component';

@NgModule({
  declarations: [
    AppComponent,
    JoinRoomComponent,
    ParticipantListComponent,
    MeetingStatusComponent,
    GameComponent,
    LeaveButtonComponent,
    GameButtonsComponent,
    BuildingBlockComponent,
    AnswersBlockComponent,
    EndScreenComponent,
    VotingBlockComponent,
    CreateRoomComponent,
    UtilitiesComponent,
    HomeComponent,
    CardComponent,
    StartBlockComponent,
    SectionDefinerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    provideFirebaseApp(() => initializeApp(environment.firebase)), //ng g environments -> add firebase config to it which you can find in the firebase console
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFunctions(() => {
      const f = getFunctions();
      f.region = 'europe-west1';
      return f;
    }),
    ServiceWorkerModule.register('/firebase-messaging-sw.js', {
      enabled: true,
    }),
    VCilitatorModule,
  ],
  providers: providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
