<div class="card m-2">
  <div class="card-body">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="card-subtitle text-body-secondary">
        {{ isAuthorAnonymous ? "Anonymous" : getUserName(cardData!.author) }}
      </h6>
      <ng-content></ng-content>
    </div>
    <p class="card-text">
      {{ cardData!.text }}
    </p>
  </div>
</div>
