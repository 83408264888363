<div class="d-flex justify-content-center">
  <button *ngIf="shouldShowStartButton()" class="btn btn-lg btn-primary m-4"
    [disabled]="!core.roomIsReady()" (click)="startGame()">
    Start meeting
  </button>
  <div class="btn-group" role="group" aria-label="Meeting controls">
    <button *ngIf="shouldShowFinishButton() && !shouldShowNextActivityWithTimerWarning()" type="button"
      class="btn btn-lg btn-primary" (click)="nextActivity()">
      <i class="fa-solid fa-flag-checkered fa-xl"></i>
    </button>

    <button *ngIf="shouldShowFinishButton() && shouldShowNextActivityWithTimerWarning()" type="button"
      class="btn btn-lg btn-primary" data-bs-toggle="modal" data-bs-target="#timerWarningFinalModal">
      <i class="fa-solid fa-flag-checkered fa-xl"></i>
    </button>

    <button *ngIf="shouldShowNextActivity() && !shouldShowNextActivityWithTimerWarning()" type="button"
      class="btn btn-lg btn-primary" (click)="nextActivity()">
      <i class="fa-solid fa-forward fa-xl"></i>
    </button>

    <button *ngIf="shouldShowNextActivity() && shouldShowNextActivityWithTimerWarning()" type="button"
      class="btn btn-lg btn-primary" data-bs-toggle="modal" data-bs-target="#timerWarningModal">
      <i class="fa-solid fa-forward fa-xl"></i>
    </button>

    <button *ngIf="shouldShowPauseButton()" type="button" class="btn btn-lg btn-primary" (click)="pauseGame()">
      <i class="fa-solid fa-pause fa-xl"></i>
    </button>
  </div>

</div>

<div class="modal fade" id="timerWarningFinalModal" tabindex="-1" aria-labelledby="timerWarningModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Timer</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        The timer has not finished counting down yet. Are you sure you want to finish the meeting prematurely?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Go back</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="nextActivity()">Finish
          Meeting</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="timerWarningModal" tabindex="-1" aria-labelledby="timerWarningModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Timer</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        The timer has not finished counting down yet. Are you sure you want to move on to the next activity
        prematurely?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Go back</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="nextActivity()">Next
          Activity</button>
      </div>
    </div>
  </div>
</div>