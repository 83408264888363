import { Injectable } from '@angular/core';
import { CURRENT_TIMER, PUBLIC } from './package-entries';
import { VCRoomService } from '@gametailors/v-cilitator-angular';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Handles the timer for a room. 
 * Each room can have at most one timer.
 */
export class TimerService {
  private timerSub?: Subscription
  private currentTimerStart?: Date
  private currentTimerDuration?: number

  private currentTimer: any
  

  private currentTime: number = 0

  constructor(
    private vcr: VCRoomService
  ) { }

  // is the timer active
  public isActive(): boolean {
    return this.currentTimerStart !== undefined
  }

  public getTimeLeft(): number | undefined {
    if (this.currentTimerDuration === undefined) return undefined

    return this.currentTimerDuration - this.currentTime
  }

  public startTimer(duration: number) {
    // Start the timer
    this.setTimerData({
      startTime: new Date().getTime(), 
      duration: duration
    })
  }

  private setTimerData(data: any) {
    // Set the timer data in the database
    this.vcr.setValueInPackage(this.vcr.currentRoomCode, PUBLIC, CURRENT_TIMER, data)
  }

  // Initialized the service
  public init(
    interval: number = 100
  ) {
    // Get the timer data from the database
    this.timerSub = this.vcr.getValueInPackage(this.vcr.currentRoomCode, PUBLIC, CURRENT_TIMER).subscribe(data => {
      if (!data) {
        // If there is no timer data, delete the timer data
        this.deleteTimerData()
      } else {
        this.currentTimerStart = new Date(data.startTime)
        this.currentTimerDuration = data.duration
        // Start the timer on the client side
        this.startClientSideTimer(
          interval, 
          this.currentTimerStart!, 
          this.currentTimerDuration!, 
        )
      }
    })
  }

  private startClientSideTimer(
    interval: number, 
    currentTimerStart: Date, 
    currentTimerDuration: number, 
  ) {
    // Clear the current timer if there is one
    if (this.currentTimer !== undefined) {
      clearInterval(this.currentTimer)
    }
    // REMARK: We use the system time to synchronise timer, this may go wrong in a few edge cases if there is something wrong with the system time
    this.currentTime = new Date().getTime() - currentTimerStart.getTime()

    this.currentTimer = setInterval(() => {
      this.currentTime = new Date().getTime() - currentTimerStart.getTime()

      if (this.currentTime > currentTimerDuration) {
        clearInterval(this.currentTimer)
      }
    }, interval)
  }

  private deleteTimerData() {
    clearInterval(this.currentTimer)
    this.currentTimer = undefined
    this.currentTimerStart = undefined
    this.currentTimerDuration = undefined
  }

  // Destroys the service
  public destroy() {
    this.timerSub?.unsubscribe()
    clearInterval(this.currentTimer)
  }

  // stops and destroys the current timer
  public killTimer() {
    this.setTimerData({})
  }
}
