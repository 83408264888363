<div class="d-flex justify-content-end">
  <button
    type="button"
    style="background: none; height: auto"
    (click)="leave()"
    class="btn-close btn-lg"
    aria-label="Leave"
  >
    <i class="fa-solid fa-right-from-bracket fa-lg"></i>
  </button>
</div>
