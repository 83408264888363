
<h3> Summary </h3>

Total amount of answers: {{getSummary()}}

<br>

<button (click)="downloadData()" class="btn btn-lg btn-primary">
  Download data
</button>
