
// Download the data as a file
export function download(filename: string, text: string, fileType: string = 'text/csv') {
  // We have to create an element here since only the dom can download data
  var element = document.createElement('a');
  element.setAttribute('href', `data:${fileType};charset=utf-8,` + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}