<div class="container d-flex justify-content-center">
  <form action="" class="d-flex flex-column justify-content-center" onsubmit="return false">    
    
    Room code: {{roomCode}}

    <label *ngIf="!vca.loggedInWithGoogle" for="input-username" class="form-label">Name</label>
  
    <ng-container
    *ngIf="!vca.loggedInWithGoogle"
    >
      <input
      type="text"
      id="input-username"
      class="form-control"
      placeholder="John Doe"
      #username
      required="true"
    />
    
    <button
      (click)="joinRoomWithCode()"
      class="btn btn-primary m-2"
    >
      Join room without logging in
    </button>
    </ng-container>
    

    <div *ngIf="!vca.loggedInWithGoogle">or</div>

    <button *ngIf="!vca.loggedInWithGoogle" (click)="login()" class="btn btn-primary m-2">
      Login with google
    </button>

    <span *ngIf="vca.loggedInWithGoogle"> Name: {{displayUserName}} </span>


    <button
      *ngIf="vca.loggedInWithGoogle"
      (click)="joinRoomWithCode()"
      class="btn btn-primary m-2"
      [disabled]="!readyToJoinWithGoogle"
    >
      Join room
    </button>

    <button *ngIf="vca.loggedInWithGoogle" (click)="logout()" class="btn btn-primary m-2">
      Logout
    </button>

    <button
      (click)="cancel()"
      class="btn btn-primary m-2"
    >
      Cancel
    </button>

  </form>
</div>
