import { Component } from '@angular/core';
import { VCAuthService } from '@gametailors/v-cilitator-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  constructor (
    protected vca: VCAuthService,
    private router: Router
  ) {}

  protected login() {
    // Logs the user in with Google's login popup
    this.vca.googleLogin()
  }

  protected logout() {
    // Logs the user out
    this.vca.logout()
  }

  protected joinRoomWithCode(code: string) {
    // Joins the room with the given code
    // This is done by navigating to the join-room route with the room query parameter
    this.router.navigate(["join-room"], {queryParams: { room: code }})
  }
}
