import { Component } from '@angular/core';
import { BlockVariant } from 'src/template/BuildingBlockData';
import { BlockService } from '../block.service';

@Component({
  selector: 'app-section-definer',
  templateUrl: './section-definer.component.html',
  styleUrls: ['./section-definer.component.scss'],
})
export class SectionDefinerComponent {
  constructor(private blockService: BlockService) {}

  protected get title(): string {
    // Returns the title of the block
    const block = this.blockService.currentBlock;
    // Check if the block is a section definer and return the title
    if (block?.variant === BlockVariant.SECTION_DEFINER) {
      return block.settings.name.value;
    } else {
    // Otherwise return an empty string
      return '';
    }
  }
}
