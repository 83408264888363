export enum FlowConnectionVariant {
  FacilitatorApproves = "facilitatorApproves",
  Timer = "timer"
}

export type FlowConnectionData 
  = ManualFlowConnection
  | TimerFlowConnection


export type ManualFlowConnection = {
  source: string,
  target?: string,
  variant: FlowConnectionVariant.FacilitatorApproves
}

export type TimerFlowConnection = {
  source: string,
  target?: string,
  variant: FlowConnectionVariant.Timer,
  duration: number
}