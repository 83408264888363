import { Component, Input } from '@angular/core';
import { Card } from '../models/Card';
import { MeetingService } from '../meeting.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
// Displays an individual card
export class CardComponent {
  @Input() cardData?: Card;

  // Whether the author should be anonymised
  @Input() isAuthorAnonymous: boolean = false;

  constructor(private core: MeetingService) {}

  protected getUserName(uid: string): string {
    // Returns the name of the user with the given uid
    return this.core.getNameOfUser(uid);
  }
}
