
export enum FilterType {
  Everything = "everything",
  Best = "best",
  Worst = "worst",
  Random = "random",
  MinVotes = "minVotes"
}

export type FilterData 
  = EverythingFilterData
  | BestFilterData
  | WorstFilterData
  | RandomFilterData
  | AtLeastNVotesFilterData

export type EverythingFilterData = {
  variant: FilterType.Everything,
}

export type BestFilterData = {
  variant: FilterType.Best,
  amount: number
}

export type WorstFilterData = {
  variant: FilterType.Worst,
  amount: number
}

export type RandomFilterData = {
  variant: FilterType.Random,
  amount: number
}

export type AtLeastNVotesFilterData = {
  variant: FilterType.MinVotes,
  amount: number
}

