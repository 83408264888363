import { FilterData, FilterType } from 'src/template/FilterData';
import { arrayGroupBy, pair, selectRandomN } from "./utils"
import { CardGroupWithData } from './models/CardGroupWithData';


function getTotalVotes(votes: {[id: string]: number}): number {
  // Returns the total amount of votes of a card
  let total = 0

  // Add the votes of each user to the total
  for (const count of Object.values(votes)) {
    total += count
  }

  return total
}


function getCardVotes(card: CardGroupWithData): number {
  // Returns the total amount of votes of a card.
  // If a card has not been voted on we return 0
  if (card.data.votes !== undefined) {
    return getTotalVotes(card.data.votes)
  } else {
    return 0
  }
}


function getCardScore(card: CardGroupWithData): number {
  // Returns a quantity called 'score' for each card.
  // This 'score' is used in the best-x and worst-x filters and it is not specified that this is always just the votes.
  return getCardVotes(card)  
}


function bestScoreFilter(
  amount: number, 
  cards: CardGroupWithData[], 
  scoreFn: (card: CardGroupWithData) => number
): CardGroupWithData[] {
  // Returns the best 'amount' cards from 'cards' according to 'scoreFn'.

  // Group the cards by score
  let byScore = arrayGroupBy(cards, card => "" + scoreFn(card))
  
  let byScoreList = 
    Object.entries(byScore)
    .map(([score, count]) => pair(+score, count))
  // Sort the groups by score
  byScoreList.sort(([score1, _1], [score2, _2]) => score1 - score2)

  // Get the best 'amount' groups
  let res: CardGroupWithData[] = []

  while (res.length < amount) {
    const cardsToAdd = byScoreList.pop()

    if (cardsToAdd === undefined) {
      break
    }

    res.push(...cardsToAdd[1])
  }

  return res
}

function applyFilter(cards: CardGroupWithData[], filter: FilterData): CardGroupWithData[] {
  // Applies a filter to a list of cards and returns the filtered list
  switch (filter.variant) {
    case FilterType.Everything:
      return cards
    case FilterType.Best:
      return bestScoreFilter(filter.amount, cards, getCardScore)
    case FilterType.Worst:
      return bestScoreFilter(filter.amount, cards, x => -getCardScore(x))
    case FilterType.Random:
      return selectRandomN(cards, filter.amount)
    case FilterType.MinVotes:
      return cards.filter(c => getCardVotes(c) >= 1)
  }
}

export function applyAllFilters(cards: CardGroupWithData[], filters: FilterData[]): CardGroupWithData[] {
  // Applies all filters to a list of cards and returns the filtered list
  // The filters are applied in the order they are given
  let outCards = cards

  for (let filter of filters) {
    outCards = applyFilter(outCards, filter)
  }

  return outCards
}
