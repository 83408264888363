import { Injectable } from '@angular/core';
import { BuildingBlockData } from 'src/template/BuildingBlockData';

@Injectable({
  providedIn: 'root'
})
export class BlockService {
  
  public currentBlockId?: string
  public currentBlock?: BuildingBlockData

  constructor() { }

  // Updates the service with new data
  public update(
    currentBlockId: string, currentBlock: BuildingBlockData | undefined
  ) {
    // Update the current block
    this.currentBlockId = currentBlockId
    this.currentBlock = currentBlock
  }

  // Clear the data for this service
  public clearData() {
    // Clear the current block
    this.currentBlockId = undefined
    this.currentBlock = undefined
  }

  public getFlowConnectionsOut(): string[] | undefined {
    // Get the flow connections of the current block
    if (this.currentBlock === undefined)                    return undefined
    if (this.currentBlock.flowConnectionsOut === undefined) return undefined
    
    return this.currentBlock.flowConnectionsOut
  }
}
