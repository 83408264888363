import { Injectable } from '@angular/core';
import {
  FlowStates,
  VCAuthService,
  VCRoomService,
  VcFlowService,
} from '@gametailors/v-cilitator-angular';
import { Subscription } from 'rxjs';
import { User } from './models/User';
import { Router } from '@angular/router';
import { FirebaseData } from '@gametailors/v-cilitator-angular/lib/models/firebase-data';
import { RoomPackageService } from './room-package.service';
import { dataToCsv } from './data-export';
import { TimerService } from './timer.service';
import { Roles } from './models/Roles';

function initVCFlowWithEnterRoomDetection(
  vcr: VCRoomService,
  vcFlow: VcFlowService,
  onStateChange: (state: FlowStates) => void,
  onEnterRoom: () => void
) {
  let previousRoom: string | undefined = undefined;

  vcFlow.init((state) => {
    onStateChange(state);

    if (previousRoom !== vcr.currentRoomCode) {
      onEnterRoom();
    }
    previousRoom = vcr.currentRoomCode;
  });
}


@Injectable({
  providedIn: 'root',
})
/**
 * This class contains most of the central game logic
 */
export class MeetingService {
  public gameState?: FlowStates = undefined;

  private subscriptions: Subscription[] = [];

  // This value is automaticaly updated whenever it changes in the database
  public joinedUsers: { [id: string]: User } = {};

  constructor(
    private vcFlow: VcFlowService,
    private vca: VCAuthService,
    private vcr: VCRoomService,
    private router: Router,
    public timerService: TimerService,
    public packageService: RoomPackageService,
  ) {}

  /* ------------------------------- Life cycle ------------------------------- */

  public init() {
    initVCFlowWithEnterRoomDetection(
      this.vcr,
      this.vcFlow,
      (state) => {
        // on state change
        this.gameState = state;
      },
      () => {
        // on enter room
        this.getJoinedUsers();
        this.packageService.onEnterRoom();
      }
    );
  }


  public destroy() {
    this.packageService.destroy();

    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  /* ----------------------------- Game controlls ----------------------------- */

  // TODO: Move this stuff to somewher else
  public async nextActivity() {
    // Move to the next activity
    
    this.timerService.killTimer();
    // If we are in the last block, finish the meeting
    if (this.isFinalBlock()) {
      await this.packageService.finishMeeting();
      this.finish();
      return;
    }
    // Otherwise, move to the next block
    await this.packageService.selectNextBlock();
    // Call maybeInitTimer to start the timer if the next block has a timer
    this.maybeInitTimer();
  }

  public joinRoom(
    code: string,
    role: string,
    name: string,
    shouldTakeLead: boolean,
    onComplete: (res: any) => void,
    onError: (err: any) => void
  ) {
    // Upon joining a room, we set the user role
    this.setUserRole(role);

    // Join the room using the VCRoomservice
    this.vcr
      .joinRoom(this.vca.currentUserUid, role, code, name, Roles.FACILITATOR)
      .then((isJoined: boolean) => {
        if (isJoined) {
          if (shouldTakeLead) {
            this.vcr.takeLeadInRoom(code, () => {
              console.log('took lead');
            });
          }
          // When joining is succesfull, navigate to the meeting page
          this.router.navigate(['meeting']);

          onComplete(isJoined)
        }
      })
      .catch((err) => {
        console.log('Could not join room', err);
        onError(err);
      });
    
    this.vcr.joinedRoom.subscribe((room: any) => {
      console.log(room);
      sessionStorage.setItem('gameCode', code);
      name
        ? sessionStorage.setItem('userName', name)
        : sessionStorage.setItem('userName', 'Naamloos');
    });
  }
  /* ------------------------------ Other methods ----------------------------- */

  public get flowEvents() {
    return this.packageService.flowEventService
  }
  public get cards() {
    return this.packageService.cardsReading
  }

  public roomIsReady(): boolean {
    return this.packageService.roomIsReady()
  }

  public isGameRunning(): boolean {
    return this.gameState === FlowStates.GAME_STARTED;
  }

  public gameHasFinished(): boolean {
    return this.gameState === FlowStates.GAME_FINISHED;
  }

  public isFinalBlock(): boolean {
    return this.packageService.isFinalBlock();
  }

  public getNameOfUser(uid: string): string {
    return this.joinedUsers[uid]?.name || 'unknown'
  }

  // TODO: Make this work in case we get null values
  public async getCsvOutput(): Promise<string> {
    // Get the data from the package service
    const flowEvents = this.flowEvents.flowEvents
    const flowEventsByBlock = this.flowEvents.flowEventsByBlock
    // Return the data as a csv string
    return dataToCsv(
      this.packageService.template!,
      Object.values(this.joinedUsers), // TODO: Record all users which have ever joined so that we remember users which have left half way through
      this.cards.cards,
      this.cards.cardGroups,
      flowEventsByBlock,
      flowEvents
    );
  }




  
  private getJoinedUsers() {
  // Set up the listeners which updates the joined users field
  // This method also calls the leftRoom method whenever you are no longer present in the room
    this.subscriptions.push(
      this.vcr.users.subscribe((joinedUsers: FirebaseData) => {
        console.log('Joined users changed', joinedUsers);

        if (joinedUsers.data) {
          this.joinedUsers = {};

          let selfPresent = false;
          // Loop over all joined users and add them to the joinedUsers field
          for (const [uid, user] of Object.entries(joinedUsers.data)) {
            if (this.vca.currentUserUid === uid) {
              selfPresent = true;
            }
            this.joinedUsers[uid] = Object.assign(user as any, { uid: uid });
          }
          // If you are no longer present in the room, call the leftRoom method
          if (!selfPresent) {
            this.leftRoom();
          }
        }
      })
    );
  }

  private leftRoom() {
    // When you leave the room, navigate back to the home page
    console.log('leaving room');
    this.vcr.stopRoomPinging();
    // Remove the game code, user name and role from the session storage
    sessionStorage.removeItem('gameCode');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('role');

    this.router.navigate(['']);
  }

  private finish() {
    // Finish the game using the VCRoomservice
    this.vcr.finishRoom(
      this.vcr.currentRoomCode,
      (res) => {
        console.log('Game finished', res);
      },
      (err) => {
        console.log('Could not finish game', err);
      }
    );
  }

  private maybeInitTimer() {
    // If the next block has a timer, start the timer
    const timerFlowConnection = this.packageService.getTimerFlowConnection();

    if (timerFlowConnection !== null) {
      this.timerService.startTimer(timerFlowConnection.duration);
    }
  }

  private setUserRole(role: string): void {
    sessionStorage.setItem('role', role);
  }
}
