import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JoinRoomComponent } from './join-room/join-room.component';
import { GameComponent } from './game/game.component';
import { CreateRoomComponent } from './create-room/create-room.component';
import { UtilitiesComponent } from './utilities/utilities.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  // Home
  { path: "", component: HomeComponent }, 
  // User arives here after pressing join room
  { path: "join-room", component: JoinRoomComponent }, 
  // An actual meeting
  { path: "meeting", component: GameComponent }, 
  // User arives here from the manager
  { path: "create-room", component: CreateRoomComponent }, 
  // Only used for debugging purposes
  { path: "utils", component: UtilitiesComponent }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
