import { Component } from '@angular/core';
import { MeetingService } from '../meeting.service';
import { CardGroupWithData } from '../models/CardGroupWithData';
import { VCAuthService } from '@gametailors/v-cilitator-angular';
import { BlockVariant } from 'src/template/BuildingBlockData';
import {
  Author,
  MaxNumberOfVotesPerAnswerpp,
  MaxNumberOfVotesPerAuthorpp,
} from 'src/template/SettingValues';
import { Card } from '../models/Card';
import { BlockService } from '../block.service';
import { FlowEventService } from '../flow-event.service';

@Component({
  selector: 'app-voting-block',
  templateUrl: './voting-block.component.html',
  styleUrls: ['./voting-block.component.scss'],
})
export class VotingBlockComponent {
  protected _Object = Object;

  constructor(
    private vca: VCAuthService, 
    private core: MeetingService,
  ) {}

  private get flowEventService() { return this.core.packageService.flowEventService }
  private get blockService() { return this.core.packageService.blockService }

  private get cardGroups(): { [groupId: string]: CardGroupWithData } {
    // Returns all the cards for the current block, as groups
    // Where each group is an id and a list of cards
    const cards = this.flowEventService.currentOutput;
    return cards || {};
  }

  protected get title(): string {
    // Returns the title of the block
    const block = this.blockService.currentBlock;

    // Check if the block is a voting block, if not return empty string
    if (block?.variant !== BlockVariant.VOTING) return '';
    // Otherwise return the title
    return block.settings.title.value;
  }

  protected get instructions(): string {
    // Returns the instructions for the block
    const block = this.blockService.currentBlock;
    // Check if the block is a voting block, if not return empty string
    if (block?.variant === BlockVariant.VOTING) {
      return block.settings.instructions.value;
    } else {
      return '';
    }
  }

  protected get isAuthorAnonymous(): boolean {
    // Returns true if the author must be anonymous
    const block = this.blockService.currentBlock;

    if (block?.variant === BlockVariant.VOTING) {
      return block.settings.author.value === Author.Make_anonymous;
    } else {
      return false;
    }
  }

  protected isUserAuthorOfCard(card: Card): boolean {
    // Returns true if the user is the author of the card
    return card.author === this.vca.currentUserUid;
  }

  protected get cardGroupList(): CardGroupWithData[] {
    // Returns the card groups as an array
    return Object.values(this.cardGroups);
  }

  protected getUserName(uid: string): string {
    // Returns the name of the user with the given uid
    return this.core.getNameOfUser(uid);
  }

  private getVotes(cardId: string): { [userId: string]: number } {
    // Returns the votes for the card with the given id
    return this.cardGroups[cardId]?.data.votes || {};
  }

  protected getTotalCardVotes(cardId: string): number {
    // Returns the total number of votes for the card with the given id
    let total = 0;

    // For each user that voted on the card, add their amount of votes to the total
    for (const number of Object.values(this.getVotes(cardId))) {
      total += number;
    }

    return total;
  }

  private getOwnVoteNumberForCardGroup(cardId: string): number {
    // Returns the number of votes the user has given to the cardgroup with the given id
    const uid = this.vca.currentUserUid;
    const votes = this.getVotes(cardId);

    return votes[uid] || 0;
  }

  private getMaxVoteNumberForAuthorsInGroup(cardGroupId: string): number {
    // This checks the number of votes of this user for each author in the group
    // And returns the maximum number of votes for any author in the group
    let votesPerAuthor: { [authorId: string]: number } = {};

    // Initialize the votesPerAuthor object with 0 votes for each author
    for (const card of Object.values(this.cardGroups[cardGroupId]?.cards)) {
      votesPerAuthor[card.author] = 0;
    }

    // Find the number of votes of this user for each author in the group
    for (const author in votesPerAuthor) {
      // For each author go over all groups and check if the a card of this author is in the group
      for (const cardGroup of this.cardGroupList) {
        let hasAuthor = false;
        
        for (const card of Object.values(cardGroup.cards)) {
          if (card.author === author) {
            hasAuthor = true;
            break;
          }
        }

        // If the group has a card of this author, add the number of votes of this user for this group to the total
        if (hasAuthor) {
          votesPerAuthor[author] += this.getOwnVoteNumberForCardGroup(
            cardGroup.groupId
          );
        }
      }
    }

    // Return the maximum number of votes for any author in the group
    return Math.max(...Object.values(votesPerAuthor));
  }

  private getTotalUserVotes(): number {
    // Returns the total number of votes the user has given
    let total = 0;

    // Simply go over all groups and add the number of votes of this user for each group to the total
    for (const cardGroup of this.cardGroupList) {
      total += this.getOwnVoteNumberForCardGroup(cardGroup.groupId);
    }

    return total;
  }

  private get voteLimit(): number {
    // Returns the maximum number of votes the user can give
    const block = this.blockService.currentBlock;

    if (block?.variant === BlockVariant.VOTING) {
      return block.settings.voteLimit.value;
    } else {
      return 0;
    }
  }

  protected canAddVote(cardId: string): boolean {
    // Returns true if the user can add a vote to the cardgroup with the given id
    // This is determined by the general vote limit, the vote limit per card and the vote limit per author
    const block = this.blockService.currentBlock;

    if (block?.variant !== BlockVariant.VOTING) return false;

    // Check if the user has reached the general vote limit
    const reachedVoteLimit = this.getTotalUserVotes() >= this.voteLimit;

    // Check if there is a vote limit per card
    const shouldLimitVotesPerCard =
      block.settings.maxNrOfVotesPerCardppOption.value ===
      MaxNumberOfVotesPerAnswerpp.Amount_pp;
    // Check if the user has reached the vote limit for this card
    const reachedVoteLimitForThisCard =
      this.getOwnVoteNumberForCardGroup(cardId) >=
      block.settings.maxNrOfVotesPerCardpp.value;

    // Check if there is a vote limit per author
    const shouldLimitVotesPerAuthor =
      block.settings.maxNrOfVotesPerAuthorppOption.value ===
      MaxNumberOfVotesPerAuthorpp.Amount_pp;
    // Check if the user has reached the vote limit for this author
    const reachedVoteLimitForAuthor =
      this.getMaxVoteNumberForAuthorsInGroup(cardId) >=
      block.settings.maxNrOfVotesPerAuthorpp.value;

    // Check if the user is the author of any card in the group
    let containsOwnCard = false;
    for (const card of Object.values(this.cardGroups[cardId]?.cards)) {
      if (card.author === this.vca.currentUserUid) {
        containsOwnCard = true;
        break;
      }
    }

    // Return true if the user has not reached any of the set vote limits and
    // If it is not allowed to vote on own cards there is no card in the group written by the user
    // Else return false
    return (
      !reachedVoteLimit &&
      !(reachedVoteLimitForThisCard && shouldLimitVotesPerCard) &&
      !(reachedVoteLimitForAuthor && shouldLimitVotesPerAuthor) &&
      (block.settings.canVoteOnOwnCards.value || !containsOwnCard)
    );
  }

  protected canRemoveVote(cardId: string): boolean {
    // Returns true if the user can remove a vote from the cardgroup with the given id
    // Which is the case if the user has voted on the cardgroup
    return this.getVotes(cardId)[this.vca.currentUserUid] >= 1;
  }

  protected addVote(cardId: string) {
    // This adds a vote to the cardgroup with the given id
    // FIXME: This should really use atomic increment, however since this is per user this shouldn't be to big of a problem.
    const uid = this.vca.currentUserUid;

    // It increments the votes of the user for the cardgroup by 1
    this.flowEventService.updateCardData(cardId, 'votes', {
      [uid]: this.getOwnVoteNumberForCardGroup(cardId) + 1,
    });
  }

  protected removeVote(cardId: string) {
    // This removes a vote from the cardgroup with the given id
    const uid = this.vca.currentUserUid;

    this.flowEventService.updateCardData(cardId, 'votes', {
      [uid]: this.getOwnVoteNumberForCardGroup(cardId) - 1,
    });
  }
}
