import { Injectable } from '@angular/core';
import { Card } from './models/Card';

@Injectable({
  providedIn: 'root'
})
export class CardsReadingService {

  public cards: {[id: string]: Card} = {}
  public cardGroups: {[cardGroupId: string]: {[cardId: string]: ""}} = {}

  constructor() { }

  // Updates the service with new data
  public update(
    cards: {[id: string]: Card},
    cardGroups: {[cardGroupId: string]: {[cardId: string]: ""}}
  ) {
    // Update the cards and card groups
    this.cards = cards
    this.cardGroups = cardGroups
  }

  public getExistingCardsOfGroup(groupId: string): undefined | {[id: string]: Card} {
    // Get the cards of the given group that haven't been deleted
    if (!this.cardGroups[groupId]) return undefined

    let cards: {[id: string]: Card} = {}

    // Get all the cards of the group and check if they are not marked as deleted
    for (const cardId in this.cardGroups[groupId]) {
      const card = this.cards[cardId]

      if (!card.deleted)
        cards[cardId] = card
    }

    return cards
  }
}
