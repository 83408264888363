<!-- <div class="container d-flex justify-content-center">
  <form action="" class="d-flex flex-column justify-content-center" onsubmit="return false">
    
    <button (click)="login()" [disabled]="!enableLoginButton" class="btn btn-primary m-2"> 
      Login with google 
    </button>

    <div *ngIf="isCreatingRoom">Creating room ...</div>
  </form>
</div> -->

<div *ngIf="!isCreatingRoom">
  <br><br><br>
  <div class="card mx-auto w-75">
    <div class="card-body">
      <h5 class="card-title">Login</h5>
      <p class="card-text">It looks like you are not logged in. Please authenticate yourself using a Google account to
        facilitate the meeting in the runner.</p>
      <button class="btn btn-primary" (click)="login()">Authenticate with Google</button>
    </div>
  </div>
</div>

<div *ngIf="isCreatingRoom">
  <br><br><br>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <br>
  </div>
  <p style="text-align: center;">Creating a room...</p>
</div>