import { TemplateData } from 'src/template/TemplateData';
import { Card } from './models/Card';
import { FlowEvent } from './models/FlowEvent';
import { User } from './models/User';
import {
  BlockVariant,
  BuildingBlockData,
} from 'src/template/BuildingBlockData';
import { FlowConnectionData } from 'src/template/FlowConnectionData';

export function dataToCsv(
  template: TemplateData,
  users: User[],
  cards: { [id: string]: Card },
  cardGroups: { [groupId: string]: { [cardId: string]: '' } },
  flowEventsByBlock: { [block: string]: string[] },
  flowEvents: { [id: string]: FlowEvent }
) {
  /*
  SCHEMA:

  first_block

  building_blocks
  id, type

  flow_connections
  id, source, target, variant

  users
  id, name, role

  cards
  id, author, text

  card_groups
  group_id, card_id

  flow_events
  id, block, timestamp

  block_input_results
  flow_event_id, setting_name, card_group_id

  flow_event_output
  flow_event_id, card_group_id, card_data_type, data

  for each card_data_type:
  'votes':
    flow_event_id, card_group_id, 'votes', user_id, amount 
  *add more variants below if nessecery*
  */

  //TODO: Add timestamps to flow_events

  let blocksByFlowEvents: { [evtId: string]: BuildingBlockData } = {};

  for (const [blockId, evtId] of Object.entries(flowEventsByBlock)) {
    blocksByFlowEvents[evtId[0]] = template.buildingBlocks![blockId];
  }

  return [
    'first_block',
    template.firstBuildingBlock,
    '',
    'building_blocks',
    'id,type',
    buildingBlocksToCsv(template.buildingBlocks || {}),
    '',
    'flow_connections',
    'id,source,target,variant',
    flowConnectionsToCsv(template.flowConnections || {}),
    '',
    'users',
    'id,name,role',
    usersToCsv(users),
    '',
    'cards',
    'id,author,text',
    cardsToCsv(cards),
    '',
    'card_groups',
    'group_id,card_id',
    cardGroupsToCsv(cardGroups),
    '',
    'flow_events',
    'id,block,timestamp',
    flowEventsToCsv(flowEventsByBlock, flowEvents),
    '',
    'block_input_results',
    'flow_event_id,setting_name,card_group_id',
    blockInputResultsToCsv(flowEvents),
    '',
    'flow_event_output',
    'flow_event_id,card_group_id,card_data_type,data',
    flowEventOutputsToCsv(
      flowEvents,
      (evtId) => blocksByFlowEvents[evtId].variant
    ),
  ].join('\n');
}

function buildingBlocksToCsv(blocks: {
  [id: string]: BuildingBlockData;
}): string {
  // Add the block id and variant to the csv
  return Object.entries(blocks)
    .map(([id, block]) => buildingBlockToCsv(id, block))
    .join('\n');
}

function buildingBlockToCsv(id: string, block: BuildingBlockData): string {
  // Return the block as a string
  return `${id},${block.variant}`;
}

function flowConnectionsToCsv(flows: {
  [id: string]: FlowConnectionData;
}): string {
  // Add the flow id, source, target and variant to the csv
  return Object.entries(flows)
    .map((x) => flowConnectionToCsv(...x))
    .join('\n');
}

function flowConnectionToCsv(id: string, flow: FlowConnectionData): string {
  // Return the flow as a string
  return `${id},${flow.source},${flow.target},${flow.variant}`;
}

function usersToCsv(users: User[]): string {
  // Add the user id, name and role to the csv
  return users.map(userToCsv).join('\n');
}

function userToCsv(user: User): string {
  // Return the user as a string
  return `${user.uid},${user.name},${user.role}`;
}

function cardsToCsv(cards: { [id: string]: Card }): string {
  // Add the card id, author and text to the csv
  return Object.entries(cards)
    .filter(([id, card]) => !card.deleted)
    .map((x) => cardToCsv(...x))
    .join('\n');
}

function blockInputResultsToCsv(flowEvents: { [id: string]: FlowEvent }) {
  // Add the flow event id, setting name and card group id to the csv
  let entries = [];

  for (const [flowId, flow] of Object.entries(flowEvents)) {
    for (const [setting, cards] of Object.entries(
      flow.inputSettingResults || {}
    )) {
      for (const cardId in cards) {
        entries.push(`${flowId},${setting},${cardId}`);
      }
    }
  }

  return entries.join('\n');
}

function cardToCsv(id: string, card: Card): string {
  // Return the card as a string
  return `${id},${card.author},${card.text}`;
}

function cardGroupsToCsv(cardGroups: {
  [groupId: string]: { [cardId: string]: '' };
}): string {
  // Add the card group id and card id to the csv
  let results: string[] = [];

  for (const [cardGroupId, cardSet] of Object.entries(cardGroups)) {
    for (const cardId in cardSet) {
      results.push(`${cardGroupId},${cardId}`);
    }
  }
  return results.join('\n');
}

function flowEventsToCsv(
  eventsByBlock: { [block: string]: string[] },
  flowEvents: { [id: string]: FlowEvent }
): string {
  // Add the flow event id, block id and timestamp to the csv
  return Object.entries(eventsByBlock)
    .map(([block, [event]]) => flowEventToCsv(event, flowEvents[event], block))
    .join('\n');
}

function flowEventToCsv(
  id: string,
  flowEvent: FlowEvent | undefined,
  blockId: string
): string {
  // Return the flow event as a string
  return `${id},${blockId},${flowEvent?.timestamp}`;
}

function flowEventOutputsToCsv(
  flowEvents: { [id: string]: FlowEvent },
  blockTypeByFlowEvent: (id: string) => BlockVariant
): string {
  // Add the flow event id, card group id, card data type and data to the csv
  let result = [];

  for (const [flowEventId, flowEvent] of Object.entries(flowEvents)) {
    for (const [cardId, data] of Object.entries(flowEvent.output || {})) {
      const records = flowEventOutputToCsv(
        flowEventId,
        cardId,
        data,
        blockTypeByFlowEvent(flowEventId)
      );

      result.push(...records);
    }
  }

  return result.join('\n');
}

function flowEventOutputToCsv(
  flowEvent: string,
  cardId: string,
  data: any,
  blockType: BlockVariant
): string[] {
  // Return the flow event output as a string
  const prefix = `${flowEvent},${cardId},`;

  return flowEventOutputCsvRecordSuffix(data, blockType).map((x) => prefix + x);
}

function flowEventOutputCsvRecordSuffix(
  data: any,
  blockType: BlockVariant
): string[] {
  // Return the flow ouput data as a string
  switch (blockType) {
    case BlockVariant.START:
    case BlockVariant.SECTION_DEFINER:
    case BlockVariant.PAUSE:
      throw 'These building blocks should have no data';
    case BlockVariant.INDIVIDUAL_ANSWERS:
    case BlockVariant.DISCUSSION:
      return ['nothing'];
    case BlockVariant.VOTING:
      const dataWithType: { [uid: string]: number } = data.votes;
      const entries = Object.entries(dataWithType);

      if (entries.length <= 0) {
        return [`votes`];
      }

      return entries.map(([uid, votes]) => `votes,${uid},${votes}`);
  }
}
