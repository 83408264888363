<div class="container d-flex justify-content-center">
  <form action="" class="d-flex flex-column justify-content-center" onsubmit="return false">
    <label for="input-roomcode" class="form-label">Room code</label>
    <input
      type="text"
      id="input-roomcode"
      class="form-control"
      placeholder="XXXXXX"
      #roomCode
    />
    <button
      (click)="joinRoomWithCode(roomCode.value)"
      class="btn btn-primary m-2"
    >
      Join room
    </button>
    
  </form>
</div>
