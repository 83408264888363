import { Component } from '@angular/core';
import { BlockVariant } from 'src/template/BuildingBlockData';
import { BlockService } from '../block.service';

@Component({
  selector: 'app-start-block',
  templateUrl: './start-block.component.html',
  styleUrls: ['./start-block.component.scss'],
})
export class StartBlockComponent {
  constructor(private blockService: BlockService) {}

  protected get title(): string {
    // Returns the title of the meeting
    const block = this.blockService.currentBlock;

    // Check if the block is a start block
    if (block?.variant === BlockVariant.START) {
      return block.settings.name.value;
    } else {
      return '';
    }
  }

  protected get category(): string {
    // Returns the category of the meeting
    const block = this.blockService.currentBlock;

    // Check if the block is a start block
    if (block?.variant === BlockVariant.START) {
      return block.settings.category.value;
    } else {
      return '';
    }
  }

  protected get description(): string {
    // Returns the description of the meeting
    const block = this.blockService.currentBlock;

    // Check if the block is a start block
    if (block?.variant === BlockVariant.START) {
      return block.settings.description.value;
    } else {
      return '';
    }
  }

  protected get expectation(): string {
    // Returns the expectation of the meeting
    const block = this.blockService.currentBlock;

    // Check if the block is a start block
    if (block?.variant === BlockVariant.START) {
      return block.settings.expectation.value;
    } else {
      return '';
    }
  }

  protected get purpose(): string {
    // Returns the purpose of the meeting
    const block = this.blockService.currentBlock;

    // Check if the block is a start block
    if (block?.variant === BlockVariant.START) {
      return block.settings.meetingPurpose.value;
    } else {
      return '';
    }
  }
}
