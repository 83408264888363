import { Injectable } from '@angular/core';
import { FlowEvent } from './models/FlowEvent';
import { CardGroupWithData } from './models/CardGroupWithData';
import { CardsReadingService } from './cards-reading.service';
import { CardWritingService } from './card-writing.service';
import { BlockService } from './block.service';

@Injectable({
  providedIn: 'root'
})
export class FlowEventService {

  public currentFlowEventId?: string
  public currentOutput?: { [cardGroupId: string]: CardGroupWithData }
  public currentInputSettingResults?: {[setting: string]: {[cardGroupId: string]: CardGroupWithData}} 

  public flowEventsByBlock: {[blockId: string]: string[]} = {}
  public flowEvents: {[id: string]: FlowEvent} = {}

  constructor(
    private cardsReading: CardsReadingService,
    private cardsWriting: CardWritingService,
    public blockService: BlockService,
  ) { }

  public get currentFlowEvent(): undefined | FlowEvent {
    // Returns the current flow event if there is one
    if (!this.currentFlowEventId) return undefined
    
    return this.flowEvents[this.currentFlowEventId]
  }

  public update(
    flowEventsByBlock: {[blockId: string]: string[]},
    flowEvents: {[id: string]: FlowEvent},
  ): { flowEventChanged: boolean } {
    // Updates the flow event service with new data.
    const currentBlockId = this.blockService.currentBlockId!

    this.flowEvents = flowEvents
    this.flowEventsByBlock = flowEventsByBlock

    const newFlowEventId = this.flowEventsByBlock[currentBlockId]?.[0]
    const flowEventChanged = this.currentFlowEventId !== newFlowEventId

    this.currentFlowEventId = newFlowEventId
    // After setting the current flow event id we can update the current output
    this.updateCurrentOutput()
    this.updateCurrentBlockInputSettingResults()

    return { flowEventChanged: flowEventChanged }
  }

  public clearData() {
    // Clears all data from the service
    this.currentFlowEventId = undefined
    this.currentOutput = undefined
    this.currentInputSettingResults = undefined
  }

  public hasOutput(): boolean {
    // Returns true if there is a current output
    return !!this.currentOutput && Object.keys(this.currentOutput).length > 0
  }

  public getInputCardsForSetting(settingName: string): undefined | {[cardGroupId: string]: CardGroupWithData} {
    // Returns the input cards for a setting
    return this.currentInputSettingResults?.[settingName]
  }

  // OPTIMIZE: This function is called a bit to often
  private updateCurrentOutput() {
    // Updates the current output
    if (this.currentFlowEvent === undefined) return

    let cardGroups = this.getExistingOutputOfFlowEvent(this.currentFlowEvent)

    this.currentOutput = cardGroups
  }

  // OPTIMIZE: This function is called a bit to often
  private updateCurrentBlockInputSettingResults() {
    // Updates the current block input setting results
    const currentBlock = this.blockService.currentBlock
    
    // If there is no current block, current flow event or cards reading service we can't update the current block input setting results
    if (!currentBlock) return
    if (!this.currentFlowEvent) return 
    if (!this.currentFlowEvent.inputSettingResults) return 

    const currentBlockInputSettingResultsSet = this.currentFlowEvent.inputSettingResults

    let currentBlockInputSettingResults: {[setting: string]: {[cardGroupId: string]: CardGroupWithData}} = {}
    // For each setting in the current block check if there is a input setting result for it
    // If there is, add the card group to the current block input setting results
    for (const [settingName, setting] of Object.entries(currentBlock.settings)) {

      if (!(settingName in currentBlockInputSettingResultsSet)) continue

      if ("input" in setting) {
        const sourceBlock = setting.input.block

        const cardGroups = this.getExistingOutputOfBlock(sourceBlock)

        currentBlockInputSettingResults[settingName] = {}

        for (const cardGroup of cardGroups) {
          if (cardGroup.groupId in currentBlockInputSettingResultsSet[settingName]) {
            currentBlockInputSettingResults[settingName][cardGroup.groupId] = cardGroup
          }
        }
      }
    }

    this.currentInputSettingResults = currentBlockInputSettingResults
  }

  // TODO: Replace other similar methods with this
  private getExistingOutputOfFlowEvent(flowEvent: FlowEvent): { [cardGroupId: string]: CardGroupWithData } {
    // Returns the existing output of a flow event
    let cardGroups: { [cardGroupId: string]: CardGroupWithData } = {}

    // For each card group in the output of the flow event, get the existing cards of the group
    for (const [cardGroupId, data] of Object.entries(flowEvent.output || [])) {
      const cards = this.cardsReading.getExistingCardsOfGroup(cardGroupId)
      // If there are no cards in the group, continue
      if (!cards) continue
      if (Object.keys(cards).length <= 0) continue

      const group: CardGroupWithData = {
        groupId: cardGroupId,
        cards: cards,
        data: data,
      }

      cardGroups[cardGroupId] = group
    }

    return cardGroups
  }

  private getFlowEventsOfBlock(blockId: string): FlowEvent[] {
    // Returns the flow events of a block
    if (blockId === "") return []

    let flows = []

    for (let flowId of this.flowEventsByBlock[blockId]) {
      const flow = this.flowEvents[flowId]

      if (!flow) continue

      flows.push(flow)
    }

    return flows
  }

  public getExistingOutputOfBlock(blockId: string): CardGroupWithData[] {
    // Returns the existing output of a block
    // First get the flow events of the block
    const flows = this.getFlowEventsOfBlock(blockId)

    let cardGroups: CardGroupWithData[] = []
    // For each flow event get the existing output
    for (let flow of flows) {
      const newGroups = Object.values(this.getExistingOutputOfFlowEvent(flow) || {})
      // Add the new groups to the card groups
      for (let group of newGroups) {
        cardGroups.push(group)
      }
    }

    return cardGroups
  }

  // TODO: Move this somewhere else
  public async updateCardData(cardId: string, location: string, data: any) {
    await this.cardsWriting.updateCardData(this.currentFlowEventId!, cardId, location, data)
  }

  public async addNewCardToOutput(
    author: string,
    text: string,
    data: any = ''
  ): Promise<string> {
    return this.cardsWriting.addNewCardToOutput(this.currentFlowEventId!, author, text, data)
  }
  
  public addCardGroupToOutput(cardGroupId: string, data: any) {
    this.cardsWriting.addCardGroupToOutput(this.currentFlowEventId!, cardGroupId, data)
  }
}
