import { Component } from '@angular/core';
import { MeetingService } from '../meeting.service';
import { Card } from '../models/Card';
import { Roles } from '../models/Roles';
import { BlockVariant } from 'src/template/BuildingBlockData';
import { CardGroupWithData } from '../models/CardGroupWithData';
import { Author } from 'src/template/SettingValues';
import { CardWritingService } from '../card-writing.service';
import { VCAuthService } from '@gametailors/v-cilitator-angular';
import { BlockService } from '../block.service';
import { FlowEventService } from '../flow-event.service';

/**
 * This component is used for both the Individual answers and the discussion block
 */
@Component({
  selector: 'app-answers-block',
  templateUrl: './answers-block.component.html',
  styleUrls: ['./answers-block.component.scss'],
})
export class AnswersBlockComponent {
  protected _Object = Object;

  private mergeSource: CardGroupWithData | undefined;

  constructor(
    private core: MeetingService,
    private vca: VCAuthService,
    private cardService: CardWritingService
  ) {}
  protected isCurrentlyGrouping: boolean = false;
  protected showEditAnswerTextArea: boolean = false;
  protected editCardId?: string;
  protected editCard?: Card;

  private get blockService() { return this.core.packageService.blockService }
  private get flowEventService() { return this.core.packageService.flowEventService }

  protected get title(): string {
    const block = this.blockService.currentBlock;

    if (
      block?.variant === BlockVariant.INDIVIDUAL_ANSWERS ||
      block?.variant === BlockVariant.DISCUSSION
    ) {
      return block.settings.title.value;
    } else {
      return '';
    }
  }

  protected get instructions(): string {
    // Returns the instructions for the block if necessary
    const block = this.blockService.currentBlock;

    // Check if the block is a discussion or individual answers block
    if (
      block?.variant === BlockVariant.INDIVIDUAL_ANSWERS ||
      block?.variant === BlockVariant.DISCUSSION
    ) {
      // Return the instructions
      return block.settings.instructions.value;
    } else {
      // Return an empty string
      return '';
    }
  }

  protected get answerKind(): string {
    // Returns the answer kind for the block
    if (
      this.blockService.currentBlock?.variant === BlockVariant.DISCUSSION
    ) {
      // If the block is a discussion block, return 'note' as the answer kind
      return 'note';
    } else {
      // Otherwise, return 'answer' as the answer kind
      return 'answer';
    }
  }

  protected get isAuthorAnonymous(): boolean {
    // Return if the author must be anonymous
    const block = this.blockService.currentBlock;
    if (
      block?.variant === BlockVariant.DISCUSSION ||
      block?.variant === BlockVariant.INDIVIDUAL_ANSWERS
    ) {
      return block.settings.author.value === Author.Make_anonymous;
    } else {
      return false;
    }
  }

  protected submit(text: string) {
    // Creates a new card with the text and adds it to the output
    this.flowEventService.addNewCardToOutput(this.vca.currentUserUid, text);
  }

  protected submitEdit(text: string) {
    // Edits the card with the text
    this.cardService.editCard(this.editCardId!, text);
    // Hides the edit answer text area
    this.showEditAnswerTextArea = false;
  }

  protected getEditCardText(): string {
    // Returns the text of the card being edited
    if (this.editCard) {
      return this.editCard.text;
    } else {
      // If there is no card being edited, return an empty string
      return '';
    }
  }

  protected startMerge(cardGroupId: CardGroupWithData) {
    // Sets the merge source to the card group
    this.mergeSource = cardGroupId;
  }

  protected cancelMerge() {
    // Sets the merge source to undefined
    this.mergeSource = undefined;
  }

  protected completeMerge(targetGroupId: string) {
    this.cardService.mergeGroups(this.mergeSource!, targetGroupId)
    this.mergeSource = undefined;
  }

  protected async unmarkAsDuplicate(groupId: string, cardId: string) {
    // Creates a new card group with the card that is going to be removed from the group
    const newGroup = await this.cardService.createNewCardGroup(cardId);
    // Removes the card from the group
    this.cardService.removeCardFromGroup(cardId, groupId);
    // Add the new group (of the single card) to the output
    this.flowEventService.addCardGroupToOutput(newGroup, '');
  }

  protected editAnswer(cardId: string, card: Card) {
    // Shows the edit answer text area and save the card id and the card
    this.showEditAnswerTextArea = true;
    this.editCardId = cardId;
    this.editCard = card;
  }

  protected currentAnswers(): CardGroupWithData[] {
    // Returns the current answers of the block
    return Object.values(this.flowEventService.currentOutput || {});
  }

  protected getUserName(uid: string): string {
    // Returns the name of the user with the given uid
    return this.core.getNameOfUser(uid);
  }

  protected isUserAuthorOfCard(card: Card): boolean {
    return card.author === this.vca.currentUserUid
  }

  protected shouldShowChangeAnswerButtons(card: Card): boolean {
    // Show the change answer buttons if the game is running and the user is the facilitator or the author of the card
    return (
      this.core.isGameRunning() && // Game is running
      (sessionStorage.getItem('role') === Roles.FACILITATOR || // And the user is the facilitator
        card.author === this.vca.currentUserUid) // Or the user is the author of the card
    );
  }
  
  protected deleteAnswer(cardId: string) {
    console.log('Deleting card', cardId);
    // This function does not really delete the card, it just marks it as deleted
    this.cardService.deleteCard(cardId);
  }

  private get allowMarkDuplicates(): boolean {
    // Returns if the user is allowed to mark duplicates
    const currentBlock = this.blockService.currentBlock;

    // If there is no current block, return false
    if (currentBlock === undefined) return false;
    // If the current block is not a discussion or individual answers block, return false
    if (
      currentBlock.variant !== BlockVariant.INDIVIDUAL_ANSWERS &&
      currentBlock.variant !== BlockVariant.DISCUSSION
    )
      return false;
    
    // Otherwise, return if the user is a facilitator or the block allows (everyone) to mark duplicates
    return (
      !!currentBlock.settings.markDuplicates.value ||
      sessionStorage.getItem('role') === Roles.FACILITATOR
    );
  }

  protected showStartMergeButton(): boolean {
    // Show the start merge button if the user is allowed to mark duplicates and there is no merge source
    return this.allowMarkDuplicates && this.mergeSource === undefined;
  }

  protected showCancelMergeButton(groupId: string) {
    // Show the cancel merge button if the user is allowed to mark duplicates and the group is the merge source
    return (
      this.allowMarkDuplicates &&
      this.mergeSource !== undefined &&
      this.mergeSource.groupId === groupId
    );
  }
  protected showCompleteMergeButton(groupId: string) {
    // Show the complete merge button if the user is allowed to mark duplicates and the group is not the merge source
    return (
      this.allowMarkDuplicates &&
      this.mergeSource !== undefined &&
      this.mergeSource.groupId !== groupId
    );
  }

  protected showUnmergeButton(group: CardGroupWithData) {
    // Show the unmerge button if the user is allowed to mark duplicates and the group has more than one card
    return this.allowMarkDuplicates && Object.keys(group.cards).length > 1;
  }
}
