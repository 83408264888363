// This file contains different strings which 
// refer to locations in the database
export const CURRENT_BUILDING_BLOCK = "currentBuildingBlock"
export const CURRENT_FLOW_EVENT = "currentFlowEvent"
export const FLOW_EVENTS = "flowEvents"
export const CARDS = "cards"
export const OUTPUT = "output"
export const FLOW_EVENTS_BY_BLOCK = "flowEventsByBlocks"
export const PUBLIC = "public"
export const PROGRAMMING = "programming"
export const CURRENT_TIMER = "currentTimer"
export const CARD_GROUPS = "cardGroups"
export const INPUT_SETTING_RESULTS = "inputSettingResults"