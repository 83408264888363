import { Component } from '@angular/core';
import { VCRoomService } from '@gametailors/v-cilitator-angular';

@Component({
  selector: 'app-meeting-status',
  templateUrl: './meeting-status.component.html',
  styleUrls: ['./meeting-status.component.scss'],
})
// This component displays the room code
export class MeetingStatusComponent {
  // This component is used to display the status of the meeting
  constructor(
    private vcr: VCRoomService,
  ) {}

  protected get roomCode(): string {
    // Returns the code of the room
    return this.vcr.currentRoomCode;
  }
}
