import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { VCAuthService, VCFirebaseService, VCOrganizationService, VCUserService } from '@gametailors/v-cilitator-angular';

@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss']
})
export class UtilitiesComponent {
  constructor (
    protected vca: VCAuthService,
    private vco: VCOrganizationService,
    private vcu: VCUserService,
    private vcf: VCFirebaseService,
    private router: Router
  ) {}

  protected goHome() {
    // Navigates to the home page
    this.router.navigate([""])
  }

  protected registerUser(name: string, image: string) {
    // Registers the user with the given name and image
    if (this.vca.loggedInWithGoogle) {
      // If the user is logged in with Google, register them with the given name and image
      // Using the VCUserService
      this.vcu.registerAuthUser({
        email: this.vca.currentUser.email,
        info: {
          image: image,
          name: name,
        }
      })
    }
  }

  protected inviteUser(id: string) {
    // Invites the user with the given id to the organization\
    // Note: The organization that the user is invited to is the organization currently set as active 
    // And this is only possible if the user that sends the invite has rights to send invites for this organization
    this.vco.inviteUserToOrganization(id, x => {
      console.log('Invited user to organization', x)  
    }, err => {
      console.log(`Failed to invite user ${id} to organization`, err)
    })
  }

  protected acceptInvite(orgId: string) {
    // Accepts the invite to the organization with the given id
    this.vcf.callCloudFunction('acceptOrganizationInvite', { organizationId: orgId }, x => {
      console.log('Accepted organization invite', x)
    }, err => {
      console.log(`Failed to accept organization invite`, err)
    })
  }

  protected grantPermissions(uid: string) {
    // Grants the user with the given id permissions to the organization
    // Note: The organization that the user is granted permissions to is the organization currently set as active
    // And currently you can only grant all permissions to a user
    this.vco.setUserPermissionsForOrganization(uid, {
      createGames: true,
      createRooms: true,
      createTemplates: true,
      deleteGames: true,
      deleteTemplates: true,
      inviteUsers: true,
      kickUsers: true,
      resetRooms: true,
      updateGames: true,
      updateOrganization: true,
      updateTemplates: true,
      updateUserPermissions: true
    }, x => {
      console.log('Set user permissions', x)  
    }, err => {
      console.log('Failed to set user permissions', err) 
    })
  }

  protected unregisterUser() {
    // Unregisters the user
    this.vcu.unregisterAuthUser()
  }

  protected login() {
    // Logs the user in with Google's login popup
    this.vca.googleLogin()
  }

  protected getOrganization() {
    // Gets the organization that is currently active for the user
    this.vco.getActiveUserOrganization(res => {
      console.log('Active user org', res)  
    }, err => {
      console.log('Failed to get active user orginization', err) 
    })
  }

  protected newOrganization() {
    // Creates a new organization
    // Note: The name of the organization is hard coded to SEP&CO
    this.vco.createOrganization("SEP&CO", res => {
      console.log("New organization created:", res)
    }, err => {
      console.log("Could not create organization", err) 
    })
  }
}
