<h1 class="text-center m-4">
  {{ title }}
</h1>
<h5 class="text-center">Description</h5>
<p class="text-center">{{ description }}</p>
<h5 class="text-center">Category</h5>
<p class="text-center">{{ category }}</p>
<h5 class="text-center">Meeting Purpose</h5>
<p class="text-center">{{ purpose }}</p>
<h5 class="text-center">Expectation</h5>
<p class="text-center">{{ expectation }}</p>
