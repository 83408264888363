import { Injectable } from '@angular/core';
import { VCRoomService } from '@gametailors/v-cilitator-angular';
import { CARDS, CARD_GROUPS, FLOW_EVENTS, OUTPUT, PUBLIC } from './package-entries';
import { path } from './utils';
import * as uuid from 'uuid';
import { CardGroupWithData } from './models/CardGroupWithData';

@Injectable({
  providedIn: 'root'
})
export class CardWritingService {

  constructor(
    private vcr: VCRoomService
  ) { }


  public async addNewCard(text: string, authorId: string): Promise<string> {
  // Adds a new card to the room
  // NOTE: This doesn't add the card to the output of the current flow event
    const cardId = 'card-' + uuid.v4();

    await this.vcr.updateValueInPackage(
      this.vcr.currentRoomCode,
      PUBLIC, CARDS,
      {
        [cardId]: {
          text: text,
          author: authorId,
        },
      }
    );
    return cardId;
  }

  public async editCard(cardId: string, text: string) {
  // Change the text of a card
    await this.vcr.updateValueInPackage(
      this.vcr.currentRoomCode,
      PUBLIC, path(CARDS, cardId),
      {
        text: text,
      }
    );
  }

  public async deleteCard(cardId: string) {
  // Delete a card
    await this.vcr.updateValueInPackage(
      this.vcr.currentRoomCode,
      PUBLIC, path(CARDS, cardId),
      { deleted: true }
    )
  }

  
  public async addCardGroupToOutput(flowEventId: string, cardGroupId: string, data: any) {
  // Add a card to the output of the current flow event
    await this.setCardGroupData(flowEventId, cardGroupId, data);
  }

  public async mergeGroups(source: CardGroupWithData, targetId: string) {
    // Add the cards from the source group into the target group
    for (const cardId in source.cards) {
      this.addCardToGroup(targetId, cardId);
    }
    // Delete the source group
    this.deleteCardGroup(source.groupId);
  }

  private async deleteCardGroup(cardGroupId: string) {
  // Delete a card group
    await this.vcr.setValueInPackage(
      this.vcr.currentRoomCode, PUBLIC, path(CARD_GROUPS, cardGroupId), {}
      )
  }

  public async setCardGroupData(flowEventId: string, cardGroupId: string, data: any) {
  // Add set data for a card on the current flow event
    await this.vcr.updateValueInPackage(
      this.vcr.currentRoomCode,
      PUBLIC,
      path(FLOW_EVENTS, flowEventId, OUTPUT),
      {
        [cardGroupId]: data,
      }
    );
  }

  public async updateCardData(flowEventId: string, cardId: string, location: string, data: any) {
  // Add update data for a card on the current flow event
    await this.vcr.updateValueInPackage(
      this.vcr.currentRoomCode,
      PUBLIC,
      path(FLOW_EVENTS, flowEventId, OUTPUT, cardId, location), data
    )
  }

  public async addNewCardToOutput(
    flowEventId: string,
    author: string,
    text: string,
    data: any
  ): Promise<string> {
    // Creates a new card and adds it to the output of the current flow event
    const cardId = await this.addNewCard(text, author);
    // After creating the card, create a new group for it
    const cardGroupId = await this.createNewCardGroup(cardId);
    // Add the group to the output of the current flow event
    await this.addCardGroupToOutput(flowEventId, cardGroupId, data);

    return cardId;
  }

  public async createNewCardGroup(
    cardId: string
  ): Promise<string> {
    // Creates a new group with one card

    // Generate a random group id
    const groupId = 'card-group-' + uuid.v4();
    	
    // Create the group
    await this.vcr.updateValueInPackage(
      this.vcr.currentRoomCode,
      PUBLIC, CARD_GROUPS,
      { 
        [groupId]: ""
      });
    // Add the card to the group
    this.addCardToGroup(groupId, cardId); // FIXME: Just add the card immediately

    return groupId;
  }

  private async addCardToGroup(groupId: string, cardId: string) {
    // Add a card to a group
    // It adds the cardId as key with value true
    await this.vcr.updateValueInPackage(
      this.vcr.currentRoomCode,
      PUBLIC, path(CARD_GROUPS, groupId),
      { [cardId]: "" }
    )
  }  

  public async removeCardFromGroup(cardId: string, groupId: string) {
    // Delete a card from a group
    // It adds the cardId as key with value false
    await this.vcr.setValueInPackage(
      this.vcr.currentRoomCode,
      PUBLIC, path(CARD_GROUPS, groupId, cardId),
      {}
    )
  }
}
