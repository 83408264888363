import { Component } from '@angular/core';
import { FlowStates, VCRoomService } from '@gametailors/v-cilitator-angular';
import { MeetingService } from '../meeting.service';
import { Roles } from '../models/Roles';
import { TimerService } from '../timer.service';

@Component({
  selector: 'app-game-buttons',
  templateUrl: './game-buttons.component.html',
  styleUrls: ['./game-buttons.component.scss'],
})
export class GameButtonsComponent {
  constructor(
    private vcr: VCRoomService, 
    protected core: MeetingService,
  ) {}

  protected get timerService() { return this.core.timerService }

  protected statusText(): string {
    switch (this.core.gameState) {
      case FlowStates.NOT_JOINED:
        return 'Not joined';
      case FlowStates.GAME_LOBBY:
        return 'In lobby';
      case FlowStates.GAME_STARTED:
        return 'Meeting running';
      case FlowStates.GAME_FINISHED:
        return 'Meeting finished';
      default:
        return '---';
    }
  }

  protected shouldShowNextActivityWithTimerWarning(): boolean {
    // Returns true if the next activity button should be shown
    var millis = this.timerService.getTimeLeft() || 0;
    // if the time is up or the timer is not running, show the button
    if (millis <= 0) {
      return false
    } else {
      return true
    }
  }

  protected startGame() {
    // Starts the game
    this.vcr.startRoom(
      this.vcr.currentRoomCode,
      (res) => {
        // If the game was started successfully by the VCRoomService, start the game internally
        console.log('Game started', res);
      },
      // If the game could not be started by the VCRoomService, log the error
      (err) => {
        console.log('Could not start game', err);
      }
    );
  }

  protected pauseGame() {
    // Pauses the game
    this.vcr.pauseRoom(
      this.vcr.currentRoomCode,
      (res) => {
        console.log('Game paused', res);
      },
      (err) => {
        console.log('Could not pause game', err);
      }
    );
  }

  public nextActivity() {
    // Starts the next activity
    this.core.nextActivity();
  }

  protected shouldShowNextActivity() {
    // Returns true if the next activity button should be shown
    // Which is if the game is running and the user is a facilitator and the game is not in the final block
    return (
      this.core.isGameRunning() &&
      sessionStorage.getItem('role') === Roles.FACILITATOR &&
      !this.core.isFinalBlock()
    );
  }

  protected shouldShowStartButton() {
    // Returns true if the start button should be shown
    // Which is if the game is not running and the user is a facilitator and the game is not finished
    return (
      !this.core.isGameRunning() &&
      !this.core.gameHasFinished() &&
      sessionStorage.getItem('role') === Roles.FACILITATOR
    );
  }

  protected shouldShowPauseButton() {
    // Returns true if the pause button should be shown
    // Which is if the game is running and the user is a facilitator
    return (
      this.core.isGameRunning() &&
      sessionStorage.getItem('role') === Roles.FACILITATOR
    );
  }

  protected shouldShowFinishButton() {
    // Returns true if the finish button should be shown
    // Which is if the game is running and the user is a facilitator and the game is in the final block
    return (
      this.core.isGameRunning() &&
      sessionStorage.getItem('role') === Roles.FACILITATOR &&
      this.core.isFinalBlock()
    );
  }
}
