import { Component } from '@angular/core';
import { VCAuthService, VCRoomService } from '@gametailors/v-cilitator-angular';
import { MeetingService } from '../meeting.service';
import { Roles } from '../models/Roles';

@Component({
  selector: 'app-participant-list',
  templateUrl: './participant-list.component.html',
  styleUrls: ['./participant-list.component.scss'],
})
// This component shows a list of participants 
// and relevant interactions
export class ParticipantListComponent {
  constructor(
    private vcr: VCRoomService,
    private vca: VCAuthService,
    protected core: MeetingService
  ) {}

  protected get joinedUsers() {
    // Returns the list of users who have joined the room
    return Object.values(this.core.joinedUsers);
  }

  protected get roomCode(): string {
    // Returns the code of the room
    return this.vcr.currentRoomCode;
  }

  protected kickUser(userId: string) {
    // Kicks the user with the given id from the room
    this.vcr.kickUserFromRoom(
      this.roomCode,
      userId,
      (result) => {
        console.log('User kicked', result);
      },
      (err) => {
        console.error(`error encountered while kicking user id: {userId}`);
        console.log(err);
      }
    );
  }

  protected canKickUser(userId: string): boolean {
    // Returns true if the current user is a facilitator 
    // Unless the user with the given id is the current user
    const uid = this.vca.currentUserUid;
    return (
      uid === this.vcr.getUsersWithRole(Roles.FACILITATOR)[0] && uid !== userId
    );
  }
}
