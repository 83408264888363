<h3>{{ title }}</h3>
<p>{{ instructions }}</p>

<div class="container">
  <div
    *ngFor="let cardGroup of this.cardGroupList"
    style=""
    class="row"
  >
    <app-card
      *ngFor="let cardId of _Object.keys(cardGroup.cards)"
      [cardData]="cardGroup.cards[cardId]"
      [isAuthorAnonymous]="
        !isUserAuthorOfCard(cardGroup.cards[cardId]) && isAuthorAnonymous
      "
    ></app-card>
    <span>{{ getTotalCardVotes(cardGroup.groupId) }} votes</span>
    <div
      class="btn-group btn-group-sm"
      role="group"
      aria-label="Voting controls"
    >
      <button
        type="button"
        class="btn btn-primary"
        (click)="addVote(cardGroup.groupId)"
        disabled
        [disabled]="!canAddVote(cardGroup.groupId)"
      >
        Add
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="removeVote(cardGroup.groupId)"
        disabled
        [disabled]="!canRemoveVote(cardGroup.groupId)"
      >
        Remove
      </button>
    </div>
  </div>
</div>
