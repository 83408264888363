<form action="" class="d-flex flex-column justify-content-center" onsubmit="return false">
  <button (click)="goHome()" class="btn btn-primary m-2">
    Home
  </button>
  
  <button (click)="login()" class="btn btn-primary m-2">
    Login with google
  </button>

  <button (click)="newOrganization()" class="btn btn-primary m-2">
    New orginization
  </button>

  <button (click)="getOrganization()" class="btn btn-primary m-2">
    Get orginization
  </button>

  <div *ngIf="vca.loggedInWithGoogle">
    <input type="text" id="input-register-username" placeholder="John Doe" class="form-control" #userNameInput/>

    <input type="text" id="input-register-user-image" placeholder=":D"  class="form-control" #userImageInput/>

    <button (click)="registerUser(userNameInput.value, userImageInput.value)" class="btn btn-primary m-2">
      Register user
    </button>

    <button (click)="unregisterUser()" class="btn btn-primary m-2">
      Unregister user
    </button>
  </div>

  <input type="text" id="input-target-user" placeholder="User id"  class="form-control" #targetUidInput/>

  <button (click)="inviteUser(targetUidInput.value)" class="btn btn-primary m-2">
    Invite user to orginization
  </button>

  <button (click)="grantPermissions(targetUidInput.value)" class="btn btn-primary m-2">
    Grant all permissions to user
  </button>


  <input type="text" id="input-accept-invite" placeholder="Orginization id"  class="form-control" #acceptInviteInput/>

  <button (click)="acceptInvite(acceptInviteInput.value)" class="btn btn-primary m-2">
    Accept invite to orginization
  </button>

  Logged in: {{vca.loggedIn}} <br>
  Logged in with google: {{vca.loggedInWithGoogle}}
</form>


