import { Injectable } from '@angular/core';
import { VCRoomService } from '@gametailors/v-cilitator-angular';
import { Subscription, filter, firstValueFrom } from 'rxjs';
import { CARDS, CARD_GROUPS, CURRENT_BUILDING_BLOCK, FLOW_EVENTS, FLOW_EVENTS_BY_BLOCK, INPUT_SETTING_RESULTS, PROGRAMMING, PUBLIC } from './package-entries';
import * as uuid from 'uuid';
import { BlockVariant, BuildingBlockData } from 'src/template/BuildingBlockData';
import { FlowConnectionData, FlowConnectionVariant, TimerFlowConnection } from 'src/template/FlowConnectionData';
import { TemplateData } from 'src/template/TemplateData';
import { applyAllFilters } from './filters';
import { path } from './utils';
import { FlowEventService } from './flow-event.service';
import { CardsReadingService } from './cards-reading.service';
import { BlockService } from './block.service';
import { MeetingData } from './meeting-data';


@Injectable({
  providedIn: 'root',
})
/**
 * This class handles all of the communication with the data packages from the `VCRoomService`.
 */
// TODO: Remove the filters(x => x)'s from the get functions
export class RoomPackageService {

  private subscriptions: Subscription[] = []

  public template?: TemplateData

  constructor(
    private vcr: VCRoomService,
    public cardsReading: CardsReadingService,
    public flowEventService: FlowEventService,
    public blockService: BlockService
  ) { }

  /* ------------------------------- Life cycle ------------------------------- */

  private async initializeData() {
    // Get the block id of the first block
    const blockId = this.template!.firstBuildingBlock
    // Create a new flow event for the first block
    const flowEventId = this.makeNewFlowEvent();
    // Set the current block to the first block
    await this.setCurrentBlock(blockId, flowEventId);
  }

  public async finishMeeting() {
    await this.onFlowEventComplete()
  }

  public async onEnterRoom() {
    // Clear the current data and get the template
    // Then get the public data and initialize the data
    this.clearCurrentData();

    this.template = await this.getTemplate()

    console.log('template: ', this.template)
    this.getPublicData()
    await this.initializeData()
  }

  private getTemplate(): Promise<TemplateData> {
    return firstValueFrom(
      this.vcr.getValueInPackage(this.vcr.currentRoomCode, PROGRAMMING, "")
        .pipe(filter(x => x))
    )
  }

  private onLeaveRoom() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public destroy() {
    this.onLeaveRoom()
  }

  private clearCurrentData() {
    // Clear the current blockservice and floweventservice
    this.blockService.clearData()
    this.flowEventService.clearData()
  }


  /* ------------------------------- Reading ------------------------------- */

  private getPublicData() {
    // Get the data stored in public
    this.subscriptions.push(
      this.vcr.getValueInPackage(this.vcr.currentRoomCode, PUBLIC, "")
      .subscribe(data => {
        // If there is data, update the data
        if (data) {
          this.onDataChanged(data)
        }
      })
    )
  }

  private onDataChanged(data: MeetingData) {
    const currentBlockId = data?.[CURRENT_BUILDING_BLOCK]
    // Update the cards reading
    this.cardsReading.update(
      data?.[CARDS] || {},
      data?.[CARD_GROUPS] || {}
    )

    if (!currentBlockId) return

    let currentBlock: BuildingBlockData|undefined = undefined
    // If the current block is in the template, get it
    if (this.template && this.template.buildingBlocks && this.template.buildingBlocks[currentBlockId]) {
      currentBlock = this.template.buildingBlocks[currentBlockId]
    }
    // Update the block service
    this.blockService.update(
      currentBlockId,
      currentBlock,
    )
    // Update the flow event service
    const {flowEventChanged} = this.flowEventService.update(
      data?.[FLOW_EVENTS_BY_BLOCK] || {},
      data?.[FLOW_EVENTS] || {}
    )
    // If it is there is no current output and the current block is a voting block, initialize the voting block 
    this.maybeInitVotingBlock()
    // If the flow event changed, update the input settings
    if (flowEventChanged && currentBlock) {
      this.updateInputSettingValues(currentBlock)
    }
  }

  public roomIsReady(): boolean {
    // If the template is undefined, the room is not ready
    return this.template !== undefined
  }


  public getNextBlock(): string | undefined {
  // Retrieve the id of the next block
  // NOTE: This only makes sense because we assume that flowConnection targets coincide
    if (this.template === undefined)                 return undefined
    if (this.template.flowConnections === undefined) return undefined

    const flowConnectionsOut = this.blockService.getFlowConnectionsOut()

    if (!flowConnectionsOut) return undefined
    if (flowConnectionsOut.length <= 0) return undefined

    const flowId = flowConnectionsOut[0]
    const flow = this.template.flowConnections[flowId]

    return flow?.target
  }

  public isFinalBlock(): boolean {
    // If there is no next block the current block is the final block
    return this.getNextBlock() === undefined;
  }

  public getFlowConnection(id: string): FlowConnectionData | undefined {
    // Retrieve the flow connection with the given id
    if (this.template === undefined)                 return undefined
    if (this.template.flowConnections === undefined) return undefined

    return this.template.flowConnections[id]
  }

  public getTimerFlowConnection(): TimerFlowConnection | null {
  // NOTE: This only really makes sense if we assume that there is at most one timer flow connection
    const flowConnections = this.blockService.getFlowConnectionsOut() || []

    for (const flow of flowConnections) {
      const flowConnection = this.getFlowConnection(flow)!

      if (flowConnection.variant === FlowConnectionVariant.Timer) {
        return flowConnection;
      }
    }

    return null;
  }


  /* --------------------------------- Writing -------------------------------- */
  
  private async updateInputSettingValues(block: BuildingBlockData) {
    // Get the current input settings
    let cardsForCurrentInputSettings: {[name: string]: {[cardId: string]: any}} = {}

    for (const [name, setting] of Object.entries(block.settings)) {
      if ('input' in setting) {
        // TODO: We get the existing output here, this means that in the future
        // we should really call this whenever the cards are updated aswell 
        // because some cards may get deleted.
        const output = this.flowEventService.getExistingOutputOfBlock(setting.input.block) 

        const filters = (setting.input.filters.value || [])
          .map(filterSetting => filterSetting.value)

        const groups = applyAllFilters(output, filters)

        cardsForCurrentInputSettings[name] = {}

        for (const group of groups) {
          cardsForCurrentInputSettings[name][group.groupId] = ""
        }
      }
    }
    // Update the input settings using the VCRoomservice
    this.vcr.setValueInPackage(
      this.vcr.currentRoomCode, 
      PUBLIC, 
      path(FLOW_EVENTS, this.flowEventService.currentFlowEventId!, INPUT_SETTING_RESULTS), 
      cardsForCurrentInputSettings
    )
  }


  private async maybeInitVotingBlock() {
    // TODO: Fix this currentOutput emptiness check, it should really only have to check for undefined
    // We set the output only once so if it is defined we just return immediately
    if (this.flowEventService.hasOutput()) return
    if (!this.blockService.currentBlock) return

    const block = this.blockService.currentBlock

    if (block.variant !== BlockVariant.VOTING) return

    const inputBlockCards = this.flowEventService.getInputCardsForSetting('input')

    if (!inputBlockCards) return
    // We add the card groups to the output
    for (let group of Object.values(inputBlockCards)) {
      this.flowEventService.addCardGroupToOutput(
        group.groupId, {votes: ""}
      )
    }
  }

  private async onFlowEventComplete() {
    if (!this.flowEventService.currentFlowEventId) return
    // Update the flow event with the current timestamp
    await this.vcr.updateValueInPackage(
      this.vcr.currentRoomCode, PUBLIC,
      path(FLOW_EVENTS, this.flowEventService.currentFlowEventId),
      {
        timestamp: new Date().getTime(),
      }
    )
  }

  private async setCurrentBlock(blockId: string | undefined, flowEventId: string) {
    // Set 'blockId' as the current block with flow event: 'flowEventId'
    await this.onFlowEventComplete();

    // NOTE: This line is here because otherwise it may happen that when we switch between components, the second component may believe that the data of the first component is its own.
    // However this is probably not the best way to do this.
    this.clearCurrentData();

    if (!blockId) return

    // Set the current block
    await this.vcr.setValueInPackage(
      this.vcr.currentRoomCode,
      PUBLIC,
      CURRENT_BUILDING_BLOCK,
      blockId,
    );
    // Set the current flow event
    await this.vcr.updateValueInPackage(
      this.vcr.currentRoomCode,
      PUBLIC,
      FLOW_EVENTS_BY_BLOCK,
      {
        [blockId]: [flowEventId],
      }
    );
  }

  private makeNewFlowEvent(): string {
    // Generate a new flow event id
    return 'flow-event-' + uuid.v4();
  }

  public async selectNextBlock(): Promise<void> {
    const flowEventId = this.makeNewFlowEvent();

    await this.setCurrentBlock(this.getNextBlock(), flowEventId);
  }

}