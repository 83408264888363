<div class="container-fluid d-flex flex-column overflow-hidden">
  <div class="row mt-3 game overflow-auto flex-grow-1">
    <app-participant-list *ngIf="!core.isGameRunning()" />
    <app-building-block *ngIf="core.isGameRunning()"></app-building-block>
    <app-end-screen *ngIf="core.gameHasFinished()"></app-end-screen>
  </div>
  <app-game-buttons class="row mt-3" />
  <!-- <app-facilitator-options
    *ngIf="core.isGameRunning() && userIsFacilitator()"
  /> -->
  <app-meeting-status class="row mt-3" />
</div>
