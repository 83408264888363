import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VCAuthService, VCRoomService, VCUserService } from '@gametailors/v-cilitator-angular';
import { Subscription } from 'rxjs';
import { Roles } from '../models/Roles';
import { MeetingService } from '../meeting.service';

@Component({
  selector: 'app-create-room',
  templateUrl: './create-room.component.html',
  styleUrls: ['./create-room.component.scss']
})
// This is where users arrive when they 
// pres run on a template in the manager
export class CreateRoomComponent {
  private subscriptions: Subscription[] = []

  private onLoggedInSub?: Subscription

  private templateId?: string

  // This is needed because we always try to log in once automatically
  private triedToLogIn: boolean = false
  private createRoomProcessStarted: boolean = false

  constructor(
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private vcr: VCRoomService,
    private vcu: VCUserService,
    private zone: NgZone,
    private vca: VCAuthService,
    private core: MeetingService,
  ) {}

  ngOnInit() {
    // We listen to the query params because we use them to store the template id
    const sub = this.activatedRouter.queryParams.subscribe(params => {
      this.templateId = params['template']

      if (!this.templateId) this.router.navigate([''])

      if (this.onLoggedInSub) this.onLoggedInSub.unsubscribe()

      // We want until we are logged in before we create the room
      this.onLoggedInSub = this.vca.onloggedIn.subscribe(x => {
        this.triedToLogIn = true
        this.tryStartCreateRoomProcess()
      })
    })

    this.subscriptions.push(sub)
  }

  protected get isCreatingRoom(): boolean {
    return this.createRoomProcessStarted
  }
  protected get enableLoginButton(): boolean {
    return this.triedToLogIn && !this.createRoomProcessStarted
  }

  // Logs the user  in wit google
  protected login() {
    // Logs the user in with Google's login popup
    this.vca.googleLogin()
  }

  private tryStartCreateRoomProcess() {
    // Starts the create room process if the user is logged in
    if (!this.vca.loggedInWithGoogle) {
      this.createRoomProcessStarted = false
      return
    } else {
      this.createRoomProcessStarted = true
    }
    // Continue the create room process
    this.createRoomIfLoggedIn()
  }

  private createRoomIfLoggedIn() {
    // Creates a room if the user is logged in
    if (!this.vca.loggedInWithGoogle) {
      this.createRoomProcessStarted = false
      return
    } else {
      this.createRoomProcessStarted = true
    }
    // Get the user's name by their uid and create a room under that name
    this.vcu.getUserInfo(this.vca.currentUserUid, ({userInfo}) => {
      const userName = userInfo.name
      this.createRoomWithUserName(userName)
    }, err => { 
      console.log("Failed to retrieve user info", err)  
      this.createRoomProcessStarted = false
    })
  }

  private createRoomWithUserName(userName: string) {
    // Creates a room using the VCRoomService
    this.vcr.createRoomFromTemplate(this.templateId!, ({roomCode}) => {
      console.log('room created', roomCode);
      this.zone.run(() => {
        this.core.joinRoom(roomCode, Roles.FACILITATOR, userName, true, () => {}, () => {
          this.createRoomProcessStarted = false  
        });
      });
    }, err => {
      // If there is an error, log it and stop the create room process
      console.log('Problem encountered while creating room', err)
      this.createRoomProcessStarted = false
    })
  }

  // Clear up all listeners
  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe()
    }
  }
}
